import React, { createContext, useState, useEffect, useContext } from "react";
import { db } from "../components/Others/firebase";
import { UserContext } from './userContext'; 

export const AuthorsContext = createContext({
    authors: []
});

const AuthorsContextProvider = (props) => {
    const [state, setState] = useState({authors: []});
    const { user } = useContext(UserContext);

    useEffect( () => {
        if (user) {
            console.log('mounting authors...');
            // we need to unsubscribe callback()
            const unsubscribe = db.collection("authors")
            .where("user", "==", user)
            .orderBy('name').onSnapshot((snapshot) => {
                const newAuthors = snapshot.docs.map((doc) => ({
                    id: doc.id,
                    ...doc.data()
                }));

                setState({authors: newAuthors});
            })
            return () => {
                console.log('unmounting authors');
                unsubscribe();
            }
        } else {
            return;
        }
    }, [user]);

    const compareAuthor = (a, b) => {
        const nameA = a.name.toUpperCase();
        const nameB = b.name.toUpperCase();

        let comparison = 0;
        if (nameA > nameB ) {
            comparison = 1;
        } else {
            comparison = -1;
        }
        return comparison;
    }

    const compareCountry = (a, b) => {
        if (!a.country) {a.country = ' '}
        if (!b.country) {b.country = ' '}
        const countryA = a.country.toUpperCase() + a.name.toUpperCase();
        const countryB = b.country.toUpperCase() + b.name.toUpperCase();

        let comparison = 0;
        if (countryA > countryB ) {
            comparison = 1;
        } else {
            comparison = -1;
        }
        return comparison;
    }

    const authorSetOrder = (authorOrder) => {
        let newAuthorList = [...state.authors];

        if (authorOrder === 1) {
            newAuthorList.sort(compareAuthor)
        } else {
            newAuthorList.sort(compareCountry)
        }
        setState({authors: newAuthorList});
    }

    const authorExist = (authorName) => {
        const authorList = state.authors;
        const result = authorList.find((author) => author.name === authorName);
        const returnValue = (result) ? result.id : false;

        return returnValue;
    }

    const authorDelete = (authorID) => {
        return new Promise( (resolve, reject) => {
            db.collection("authors").doc(authorID).delete()
            .then( () => {
                resolve()
            })
            .catch( (err) => {
                reject(err)
            })
        })
    }

    const authorRemoveBook = (authorID, bookID, callback) => {
        let authorDoc = db.collection("authors").doc(authorID);
        authorDoc.get().then( (author) => {
            if (author) {
                let authorCurrentBooks = [];
                let authorData = author.data();
                if (authorData.bookList) {
                    authorCurrentBooks = authorData.bookList.filter( (el) => {
                        return el.bookID !== bookID
                    })
                    authorDoc.update({
                        bookList: authorCurrentBooks
                    }).then(() => {
                        callback(undefined);
                    })
                } else {
                    callback(undefined);
                }
            } else {
                callback(undefined);
            }
        }).catch( (err) => {
            callback(err)
        })

    }

    const authorAddBook = (authorID, bookID, bookTitle) => {
        let authorDoc = db.collection("authors").doc(authorID);
        authorDoc.get().then( (author) => {
            if (author) {
                let authorCurrentBooks = [];
                let authorData = author.data();
                if (authorData.bookList) {
                    authorCurrentBooks = [...authorData.bookList]
                }
                if (!authorCurrentBooks.find( (element) => element.bookID === bookID)) {
                    authorCurrentBooks = [...authorCurrentBooks, {bookID, bookTitle}]

                    let authorSet = new Set();
                    let result = authorCurrentBooks.reduce( (acc, item) => {
                        if (!authorSet.has(item.bookID)) {
                            authorSet.add(item.bookID,item)
                            acc.push(item)
                        }
                        return acc;
                    },[])
    
                    authorCurrentBooks = [...result];
                    authorDoc.update({
                        bookList: authorCurrentBooks
                    })
                    console.log('updated');
                }
            }
        }).catch( (err) => {
            console.log('error');
            console.log(err);
        })
    }
    
    return (
        <AuthorsContext.Provider value={
            {...state, 
            authorExist: authorExist, 
            authorAddBook: authorAddBook, 
            authorDelete: authorDelete,
            authorRemoveBook: authorRemoveBook,
            authorSetOrder: authorSetOrder
            }
        }>
            {props.children}
        </AuthorsContext.Provider>
    );
}

export default AuthorsContextProvider;
