import React from 'react';

import Navbar from './components/Layout/Navbar';
import Routes from './routes';
import Footer from './components/Layout/Footer';

function App() {
  return (
    <div className="App">
      <Navbar />
      <main>
        <Routes />
      </main>
      <Footer/>
    </div>
  );
}

export default App;
