import React, { useContext} from 'react';
import { NavLink } from 'react-router-dom';
import { UserContext } from '../../contexts/userContext'; 

const SignedInMobile = () => {
    const { photoURL, displayName, email, userLogout } = useContext(UserContext);
    const userInitials = (displayName) ? displayName[0] + displayName[displayName.lastIndexOf(' ') + 1]: '?';
    
    return (
        <div>
            <li><div className="user-view">
                <div className="background">
                    <img src="images/patrick-fore-0gkw_9fy0eQ-unsplash.jpg" width="100%" height="100%" alt=""/>
                </div>
                {photoURL &&
                <NavLink to='/profile' className='btn-large btn-floating'>
                    <img src={photoURL} alt="" className="circle responsive-img"/>
                </NavLink>
                }
                {!photoURL &&
                <NavLink to='/profile' className='btn-large btn-floating purple light-1'>{userInitials}</NavLink>
                }
                <span className="white-text name">{displayName}</span>
                <span className="white-text email">{email}</span>
            </div></li>
            <li><NavLink to='/books' className="sidenav-close">Books</NavLink></li>
            <li><NavLink to='/authors' className="sidenav-close">Authors</NavLink></li>
            <li><div className="divider"></div></li>
            <li><NavLink to='/' onClick={() => {userLogout()}} className="sidenav-close">  Logout</NavLink></li>
        </div>
    )
}

export default SignedInMobile;