import React, {useContext} from 'react';
import { NavLink } from 'react-router-dom';
import { UserContext } from '../../contexts/userContext'; 

const SignedInLinks = () => {
    const { photoURL, displayName, userLogout } = useContext(UserContext);
    const userInitials = (displayName) ? displayName[0] + displayName[displayName.lastIndexOf(' ') + 1]: '?';
    return (
        <ul className="right">
            <li><NavLink to='/books'>Books</NavLink></li>
            <li><NavLink to='/authors'>Authors</NavLink></li>
            <li><NavLink to='/' onClick={() => {userLogout()}}> Logout</NavLink></li>
            {photoURL && 
            <li><NavLink to='/profile' className='btn-floating'>
                <img src={photoURL} alt="" className="circle responsive-img"/>
            </NavLink></li>}
            {!photoURL &&
            <li><NavLink to='/profile' className='btn btn-floating purple light-1'>{userInitials}</NavLink></li>}
        </ul>
    )
}

export default SignedInLinks;