import React from 'react';
// import { NavLink } from 'react-router-dom';

const SignedOutLinks = () => {
    return (
        <ul className="right">
            {/* <li><NavLink to='/signup'>Signup</NavLink></li> */}
            <li><a href="#!" className="modal-trigger" data-target="modal-signup">Signup</a></li>
            {/* <li><NavLink to='/login'>Login</NavLink></li> */}
            <li><a href="#!" className="modal-trigger" data-target="modal-login">Login</a></li>
        </ul>
    )
}

export default SignedOutLinks;