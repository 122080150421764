import React, {useEffect} from 'react';
import M from 'materialize-css';

const Slider = () => {
    useEffect(() => {
        let elements = document.querySelectorAll('.slider');
        M.Slider.init(elements, {indicators: false, duration: 1000});
    },[])
    return (
        <div className="slider">
            <ul className="slides">
                <li>
                    <img src="images/pieterjan-montens-aVCtBK8NaCo-unsplash.jpg" alt=""/>
                    <div className="caption center-align">
                        <h2>What books do I have?</h2>
                        <h5 className="light grey-text text-lighten-3">The best site in the world</h5>
                    </div>
                </li>
                <li>
                    <img src="images/patrick-tomasso-Oaqk7qqNh_c-unsplash.jpg" alt=""/>
                    <div className="caption right-align">
                        <h2>Que Livros tenho?</h2>
                        <h5 className="light grey-text text-lighten-3">The best site in the world</h5>
                    </div>
                </li>
                <li>
                    <img src="images/paul-schafer-t6oZEgL0z18-unsplash.jpg" alt=""/>
                    <div className="caption left-align">
                        <h2>¿Qué libros tengo?</h2>
                        <h5 className="light grey-text text-lighten-3">The best site in the world</h5>
                    </div>
                </li>
            </ul>
        </div>
    )
}

export default Slider;