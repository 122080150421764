import React, { useState, useContext, useEffect } from 'react';
import { db, storage } from "../Others/firebase";
import { getOpenLibraryBook } from "../Others/getOpenLibraryBook";
import { getGoodReadsBook } from "../Others/getGoodReadsBook";
import { useHistory } from 'react-router-dom';
import { UserContext } from '../../contexts/userContext'; 
import { AuthorsContext } from '../../contexts/authorsContext'; 
import { BooksContext } from '../../contexts/booksContext'; 
import M from 'materialize-css';

const CreateBook = (props) => {
    const [bookID, setBookID] = useState('');
    const [title, setTitle] = useState('');
    const [subTitle, setSubTitle] = useState('');
    const [originalTitle, setOriginalTitle] = useState('');
    const [ISBN, setISBN] = useState('');
    const [publisher, setPublisher] = useState('');
    const [authors, setAuthors] = useState(['']);
    const [illustrator, setIllustrator] = useState('');
    const [translator, setTranslator] = useState('');
    const [preface, setPreface] = useState('');
    const [genre, setGenre] = useState('');
    const [language, setLanguage] = useState('');
    const [publishYear, setPublishYear] = useState('');
    const [collection, setCollection] = useState('');
    const [topics, setTopics] = useState('');
    const [description, setDescription] = useState('');
    const [cover, setCover] = useState('');
    const [origin, setOrigin] = useState('');
    const [openLibraryID, setOpenLibraryID] = useState('');
    const [openLibraryURL, setOpenLibraryURL] = useState('');
    const [goodReadsID, setGoodReadsID] = useState('');
    const [goodReadsURL, setGoodReadsURL] = useState('');
    const [error, setError] = useState('');
    const [fileError, setFileError] = useState('');
    const [progress, setProgress] = useState();
    const { user } = useContext(UserContext);
    const { authorExist, authorAddBook } = useContext(AuthorsContext);
    const { bookExist, bookISBNExist } = useContext(BooksContext);
    const history = useHistory();

    // Load bookID into forms fields
    const loadBook = (ID) => {
        setBookID(ID);

        db.collection("books").doc(ID).get().then( (book) => {
            const bookData = book.data();
            if (bookData.title) {
                setTitle(bookData.title);
                document.getElementById('bookTitle').classList.add("input-auto-filled");
            }
            if (bookData.subTitle) {
                setSubTitle(bookData.subTitle);
                document.getElementById('bookSubTitle').classList.add("input-auto-filled");
            }
            if (bookData.originalTitle) {
                setOriginalTitle(bookData.originalTitle);
                document.getElementById('bookOriginalTitle').classList.add("input-auto-filled");
            }
            if (bookData.ISBN) {
                setISBN(bookData.ISBN);
                document.getElementById('bookISBN').classList.add("input-auto-filled");
            }
            if (bookData.publisher) {
                setPublisher(bookData.publisher);
                document.getElementById('bookPublisher').classList.add("input-auto-filled");
            }
            if (bookData.authors) {
                setAuthors(bookData.authors);
                for (let i = 0 ; i < bookData.authors.length ; i++) {
                    const inputID = 'bookAuthor' + i;
                    document.getElementById(inputID).classList.add("input-auto-filled");
                }
            }
            if (bookData.illustrator) {
                setIllustrator(bookData.illustrator);
                document.getElementById('bookIllustrator').classList.add("input-auto-filled");
            }
            if (bookData.translator) {
                setTranslator(bookData.translator);
                document.getElementById('bookTranslator').classList.add("input-auto-filled");
            }
            if (bookData.preface) {
                setPreface(bookData.preface);
                document.getElementById('bookPreface').classList.add("input-auto-filled");
            }
            if (bookData.genre) {
                setGenre(bookData.genre);
                document.getElementById('bookGenre').classList.add("input-auto-filled");
            }
            if (bookData.language) {
                setLanguage(bookData.language);
                document.getElementById('bookLanguage').classList.add("input-auto-filled");
            }
            if (bookData.publishYear) {
                setPublishYear(bookData.publishYear);
                document.getElementById('bookPublishYear').classList.add("input-auto-filled");
            }
            if (bookData.collection) {
                setCollection(bookData.collection);
                document.getElementById('bookCollection').classList.add("input-auto-filled");
            }
            if (bookData.description) {
                setDescription(bookData.description);
                document.getElementById('bookDescription').classList.add("input-auto-filled");
            }
            if (bookData.cover) {
                setCover(bookData.cover);
                document.getElementById('bookCoverURL').classList.add("input-auto-filled");
            }
            setError('');
            document.getElementById('CreateBookButton').disabled = false;

            M.updateTextFields();
        })
    }

    const titleVerify = (btitle) => {
        if (!bookID) {
            if (btitle !== '') {
                if (bookExist(btitle)) {
                    document.getElementById('bookTitleIcon').classList.remove("hide");
                } else {
                    document.getElementById('bookTitleIcon').classList.add("hide");
                }
            }
        }
    }

    const ISBNVerify = (bISBN) => {
        if (!bookID) {
            if (bISBN !== '') {
                if (bookISBNExist(bISBN)) {
                    document.getElementById('bookISBNIcon').classList.remove("hide");
                } else {
                    document.getElementById('bookISBNIcon').classList.add("hide");
                }
            }
        }
    }
    
    const nameVerify = (e, i) => {
        const name = e.target.value
        if (name !== '') {
            if (authorExist(name)) {
                document.getElementById('bookAuthorNewIcon' + i).classList.add("hide");
            } else {
                document.getElementById('bookAuthorNewIcon' + i).classList.remove("hide");
            }
        }
    }

    const uploadCover = (imageFile, callback) => {
        if (imageFile !== '') {
            const uploadTask = storage.ref(`/users/${user}/images/${imageFile.name}`).put(imageFile);

            uploadTask.on("state_changed", 
                snapshot => {
                    // task progress
                    const uploadProgress = Math.round( (snapshot.bytesTransferred / snapshot.totalBytes) * 100 );
                    setProgress({width: uploadProgress + "%"});
                },
                err => {
                    // error function
                    callback(err, undefined);
                    // setFileError(err);
                },
                () => {
                    // complete function
                    storage.ref(`/users/${user}/images/`).child(imageFile.name)
                        .getDownloadURL().then( (url) => {
                            setProgress('');
                            if (callback) {
                                callback(undefined, url);
                            }
                        })
                })
        }
    }

    const photoClick = (e) => {
        console.log('clicked');
    }

    // handle click event of the add author input
    const addAuthorField = () => {
        setAuthors([...authors, '']);
    }

    // handle click event of the remove author input
    const removeAuthorField = (i) => {
        const authorList = [...authors];
        authorList.splice(i, 1);
        setAuthors(authorList);
    }
  
    const showMoreInfo = (e) => {
        document.getElementById('subTitleDiv').classList.remove("hide");
        document.getElementById('originalTitleDiv').classList.remove("hide");
        document.getElementById('illustratorDiv').classList.remove("hide");
        document.getElementById('translatorDiv').classList.remove("hide");
        document.getElementById('prefaceDiv').classList.remove("hide");
    }

    const searchISBN = async (e) => {
        let progressBar = document.querySelectorAll('.progress');
        progressBar[0].classList.remove("hide");
        await searchGoodReads(e);
        await searchOpenLibrary(e);
        progressBar[0].classList.add("hide");
    }

    const searchOpenLibrary = async (e) => {
        if (ISBN) {
            const OLBook = await getOpenLibraryBook(ISBN);
            if (OLBook) {
                M.toast({html: 'Found info in Open Library'});
                console.log('ISBN ok');
                if (title === '' && OLBook.title) {
                    setTitle(OLBook.title);
                    document.getElementById('CreateBookButton').disabled = false;
                    document.getElementById('bookTitle').classList.add("input-auto-filled");
                }
                if (subTitle === '' && OLBook.subtitle) {
                    setSubTitle(OLBook.subtitle);
                    document.getElementById('bookSubTitle').classList.add("input-auto-filled");
                }
                if (publisher === '' && OLBook.publishers[0].name) {
                    setPublisher(OLBook.publishers[0].name);
                    document.getElementById('bookPublisher').classList.add("input-auto-filled");
                }
                if (publishYear === '' && OLBook.publish_date) {
                    setPublishYear(OLBook.publish_date);
                    document.getElementById('bookPublishYear').classList.add("input-auto-filled");
                }
                if (description === '' && OLBook.notes) {
                    setDescription(OLBook.notes);
                    document.getElementById('bookPublishYear').classList.add("input-auto-filled");
                }

                if (OLBook.authors) {
                    setAuthors([OLBook.authors[0].name]);
                }
                // if ('cover' in OLBook) {
                if (cover === '' && 'cover' in OLBook) {
                    if (OLBook.cover.large) {
                        setCover(OLBook.cover.large);
                    }
                }
                setOrigin('OpenLibrary');
                if (OLBook.identifiers.openlibrary[0]) {
                    setOpenLibraryID(OLBook.identifiers.openlibrary[0]);
                }
                if (OLBook.url) {
                    setOpenLibraryURL(OLBook.url);
                }
                M.updateTextFields();
                console.log(OLBook);
            } else {
                console.log('ISBN not found')
            }
        } else {
            console.log('ISBN is empty')
        }
    };

    const searchGoodReads = async (e) => {
        if (ISBN) {
            console.log('searching goodreads');
            const GRBook = await getGoodReadsBook(ISBN);
            if (GRBook) {
                M.toast({html: 'Found info in GoodReads'});
                console.log('GR ISBN ok');
                console.log(GRBook);
                if (title === '' && GRBook.title[0]) {
                    setTitle(GRBook.title[0]);
                    document.getElementById('CreateBookButton').disabled = false;
                    document.getElementById('bookTitle').classList.add("input-auto-filled");
                }
                if (publisher === '' && GRBook.publisher[0]) {
                    setPublisher(GRBook.publisher[0]);
                    document.getElementById('bookPublisher').classList.add("input-auto-filled");
                }
                if (publishYear === '' && GRBook.publication_year[0]) {
                    setPublishYear(GRBook.publication_year[0]);
                    document.getElementById('bookPublishYear').classList.add("input-auto-filled");
                }
                if (language === '' && GRBook.language_code[0]) {
                    switch (GRBook.language_code[0]) {
                        case 'por':
                            setLanguage('Português');
                            break;
                        case 'eng':
                            setLanguage('English');
                            break;
                        case 'esp':
                            setLanguage('Español');
                            break;
                        default:
                            setLanguage(GRBook.language_code[0]);
                    }
                    document.getElementById('bookLanguage').classList.add("input-auto-filled");
                }
                if (authors[0] === '' && GRBook.authors[0].author[0].name[0]) {
                    const newAuthors = [];
                    GRBook.authors[0].author.forEach((thisAuthor, i) => {
                        switch (thisAuthor.role[0]) {
                            case 'Ilustrator': 
                            case 'Illustrator': 
                                setIllustrator(thisAuthor.name[0]);
                                document.getElementById('bookIllustrator').classList.add("input-auto-filled");
                                break;
                            case 'Translator': 
                            case 'Tradutor': 
                            case 'Tradução': 
                                setTranslator(thisAuthor.name[0]);
                                document.getElementById('bookTranslator').classList.add("input-auto-filled");
                                break;
                            case 'Preface': 
                                setPreface(thisAuthor.name[0]);
                                document.getElementById('bookPreface').classList.add("input-auto-filled");
                                break;
                            default:
                                newAuthors.push(thisAuthor.name[0]);
                            }
                        })
                    setAuthors(newAuthors);
                    for (let i = 0 ; i < newAuthors.length ; i++) {
                        const inputID = 'bookAuthor' + i;
                        document.getElementById(inputID).classList.add("input-auto-filled");
                    }
                }
                if (description === '' && GRBook.description[0]) {
                    setDescription(GRBook.description[0]);
                    document.getElementById('bookDescription').classList.add("input-auto-filled");
                }
                if (cover === '' && GRBook.image_url[0]) {
                    if (!GRBook.image_url[0].includes("nophoto")) {
                        setCover(GRBook.image_url[0]) ;
                        document.getElementById('bookCover').classList.add("input-auto-filled");
                    }
                }
                setOrigin('GoodReads');
                if (GRBook.id[0]) {
                    setGoodReadsID(GRBook.id[0]);
                }
                if (GRBook.url[0]) {
                    setGoodReadsURL(GRBook.url[0]);
                }
                M.updateTextFields();
            } else {
                console.log('GR ISBN not found')
            }
        } else {
            console.log('ISBN is empty')
        }
    };

    // const searchISBN3 = async (e) => {
    //     if (ISBN) {
    //         const getFBBook = fb.functions().httpsCallable('getbook');
    //         console.log('vai chamar...');
    //         getFBBook({ bookISBN: ISBN })
    //         .then( (bookinfo) => {
    //             console.log('ok function');
    //             console.log(bookinfo);
    //         })
    //         .catch( err => {
    //             console.log('erro');
    //             console.log(err);
    //         });
    //     }
    // }

    const onFieldChange = (e, i) => {
        e.target.classList.remove("input-auto-filled");
        switch (e.target.id) {
            case 'bookTitle':
                setTitle(e.target.value);
                if (e.target.value === '') {
                    document.getElementById('CreateBookButton').disabled = true;
                } else {
                    document.getElementById('CreateBookButton').disabled = false;
                }
                break;
            case 'bookSubTitle':
                setSubTitle(e.target.value);
                break;
            case 'bookOriginalTitle':
                setOriginalTitle(e.target.value);
                break;
            case 'bookISBN':
                setISBN(e.target.value);
                break;
            case 'bookPublisher':
                setPublisher(e.target.value);
                break;
            case 'bookAuthor'+i:
                // console.log(i)
                const authorList = [...authors];
                authorList[i] = e.target.value;
                setAuthors(authorList);
                break;
            case 'bookIllustrator':
                setIllustrator(e.target.value);
                break;
            case 'bookTranslator':
                setTranslator(e.target.value);
                break;
            case 'bookPreface':
                setPreface(e.target.value);
                break;
            case 'bookGenre':
                setGenre(e.target.value);
                break;
            case 'bookLanguage':
                setLanguage(e.target.value);
                break;
            case 'bookPublishYear':
                setPublishYear(e.target.value);
                break;
            case 'bookDescription':
                setDescription(e.target.value);
                break;
            case 'bookCollection':
                setCollection(e.target.value);
                break;
            case 'bookCoverURL':
                setCover(e.target.value);
                break;
            case 'bookCoverFile':
                const image = e.target.files[0];
                if (image) {
                    const fileType = image["type"];
                    const validImageTypes = ["image/gif", "image/jpeg", "image/png"];
                    if (!validImageTypes.includes(fileType)) {
                        setFileError('invalid image file')
                    } else {
                        if (image.size > 16384) {
                            setFileError('image too big');
                        } else {
                            setFileError('');
                            setCover('');
                            uploadCover(image, (err, url) => {
                                if (err) {
                                    setFileError(err);
                                } else {
                                    if (url) {
                                        setCover(url);
                                        document.getElementById('bookCoverFile').value = "";
                                        // document.getElementById('bookCoverFileWrapper').value = "";
                                        M.updateTextFields();
                                    }
                                }
                            });
                        }
                    }
                } else {
                    setFileError('');
                }
                // setCover(e.target.value);
                break;
            default:
        }
    }

    const updateAuthor = async (authorName, abookid, abooktitle) => {
        if (authorName) {
            let author = authorExist(authorName);
            if (!author) {
                console.log('added author...');
                console.log(author);
                let newAuthor = await db.collection("authors").add({
                    user: user, 
                    name: authorName, 
                    photo: '/images/Nophoto.jpg',
                    createdAt: new Date()
                    });
                author = newAuthor.id;
                M.toast({html: 'Created new author'});
            }
            // console.log('update author...');
            // console.log(author);
            authorAddBook(author, abookid, abooktitle);
        }
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        console.log('translator');
        console.log(translator);
        if (title === '') {
            console.log('Book needs a title');
        } else {
            // console.log(title); 
            // console.log(subTitle); 
            // console.log(ISBN); 
            // console.log(authors);
            if (bookID) {
                db.collection("books").doc(bookID).set({
                    user: user, 
                    title, subTitle, originalTitle, ISBN, publisher, 
                    authors, illustrator, translator, preface, genre, language, 
                    publishYear, collection, topics, description, cover, 
                    origin, goodReadsID, goodReadsURL, openLibraryID, openLibraryURL,
                    updatedAt: new Date()
                }, {merge:true})
                .then( () => {
                    authors.forEach( (author) => {
                        updateAuthor(author, bookID, title)
                    });
                    setTitle('');
                    setSubTitle('');
                    setOriginalTitle('');
                    setISBN('');
                    setPublisher('');
                    setAuthors(['']);
                    setIllustrator('');
                    setTranslator('');
                    setPreface('');
                    setGenre('');
                    setLanguage('');
                    setPublishYear('');
                    setCollection('');
                    setTopics('');
                    setDescription('');
                    setCover('');
                    setOrigin('');
                    setGoodReadsID('');
                    setGoodReadsURL('');
                    setOpenLibraryID('');
                    setOpenLibraryURL('');
                    setError('');
                    history.push("/books");
                })
            } else {
                db.collection("books").add({
                    user: user, 
                    title, subTitle, originalTitle, ISBN, publisher, 
                    authors, illustrator, translator, preface, genre, language, 
                    publishYear, collection, topics, description, cover, 
                    origin, goodReadsID, goodReadsURL, openLibraryID, openLibraryURL,
                    createdAt: new Date()
                    })
                   .then((doc) => {
                        authors.forEach( (author) => {
                            updateAuthor(author, doc.id, title)
                        });
                        setTitle('');
                        setSubTitle('');
                        setOriginalTitle('');
                        setISBN('');
                        setPublisher('');
                        setAuthors(['']);
                        setIllustrator('');
                        setTranslator('');
                        setPreface('');
                        setGenre('');
                        setLanguage('');
                        setPublishYear('');
                        setCollection('');
                        setTopics('');
                        setDescription('');
                        setCover('');
                        setOrigin('');
                        setGoodReadsID('');
                        setGoodReadsURL('');
                        setOpenLibraryID('');
                        setOpenLibraryURL('');
                        setError('');
                        history.push("/books");
                    })
            }
        }
    }

    useEffect(() => {
        if (props.match.params.id) {
            // dont forget to unsubscribe callback()
            loadBook(props.match.params.id);    
        } else {
            return;
        }
    }, [props]);

    // if (props.match.params.id) {
    //     loadBook(props.match.params.id);
    // }
    

    return (
        <div className="container section">
            <form onSubmit={handleSubmit} className="white">
            {/* <div className="progress hide"><div className="indeterminate"></div></div> */}
                {/* <h5 className="grey-text text-farken-3">Create book</h5> */}
                <div className="row">
                    <div className="col s11 m3">
                        <img id="createBookCover" src={cover ? cover : '/images/nocover.png'} alt="s" className="responsive-img" width="100%" height="100%" onClick={photoClick}/>
                        <div className="file-field input-field">
                            {progress && 
                                <div className="progress">
                                    <div className="determinate" style={progress}></div>
                                </div>
                            }
                            <div className="btn">   
                                <span>Upload</span>
                                <input type="file" name="image" id="bookCoverFile" onChange={(e) => onFieldChange(e)}/>
                            </div>
                            <div className="file-path-wrapper">
                                <input className="file-path validate hide" id="bookCoverFileWrapper" type="text" placeholder="" />
                            </div>
                            <br/>
                            <br/>
                            <br/>
                            <div className="input-field">
                                <i className="material-icons prefix">public</i>
                                <input type="text" id="bookCoverURL" value={cover} onChange={(e) => onFieldChange(e)} />
                                <label htmlFor="bookCoverURL">Cover</label>
                            </div>
                            <p className="error pink-text center-align">{fileError}</p>
                        </div>
                    </div>
                    <div className="col s11 m8">
                        <div className="input-field">
                            <i id="bookTitleIcon" className="material-icons prefix purple-text hide">error</i>
                            <input type="text" id="bookTitle" value={title} onChange={(e) => onFieldChange(e)} onBlur={titleVerify(title)} />
                            <label htmlFor="bookTitle">Title</label>
                            <i className="material-icons prefix btn-prefix teal-text" onClick={showMoreInfo}>add</i>
                        </div>
                        <div id="subTitleDiv" className="input-field hide">
                            <i className="material-icons prefix"></i>
                            <input type="text" id="bookSubTitle" value={subTitle} onChange={(e) => onFieldChange(e)} />
                            <label htmlFor="bookSubTitle">Sub title</label>
                        </div>
                        <div id="originalTitleDiv" className="input-field hide">
                            <i className="material-icons prefix"></i>
                            <input type="text" id="bookOriginalTitle" value={originalTitle} onChange={(e) => onFieldChange(e)} />
                            <label htmlFor="bookOriginaTitle">Original title</label>
                        </div>
                        <div className="input-field">
                            <i id="bookISBNIcon" className="material-icons prefix purple-text hide">error</i>
                            {/* <input type="text" id="bookISBN" value={ISBN} onChange={(e) => setISBN(e.target.value)}/> */}
                            <input type="text" id="bookISBN" value={ISBN} onChange={(e) => onFieldChange(e)} onBlur={ISBNVerify(ISBN)}/>
                            <label htmlFor="bookISBN">ISBN
                            <div className="progress hide">
                                <div className="indeterminate"></div>
                            </div>
                            </label>
                            <i className="material-icons prefix btn-prefix teal-text" onClick={searchISBN}>search</i>
                        </div>
                        <div className="input-field">
                        <i className="material-icons prefix"></i>
                            <input type="text" id="bookPublisher" value={publisher} onChange={(e) => onFieldChange(e)} />
                            <label htmlFor="bookPublisher">Publisher</label>
                        </div>

                        {authors.map((author, i) => {
                            const inputID = 'bookAuthor' + i;
                            const inputNewID = 'bookAuthorNewIcon' + i;
                            return(
                                <div className="input-field" key={i}>
                                    <i id={inputNewID} className="material-icons prefix purple-text hide">fiber_new</i>
                                    <input type="text" id={inputID} value={author} onChange={(e) => onFieldChange(e, i)} onBlur={(e) => nameVerify(e, i)}/>
                                    <label htmlFor={inputID}>Author</label>
                                    {authors.length !== 1 && <i className="material-icons prefix right btn-prefix teal-text" onClick={() => removeAuthorField(i)}>remove</i>}
                                    {authors.length - 1 === i && <i className="material-icons prefix btn-prefix teal-text" onClick={addAuthorField}>add</i>}
                                </div> 
                            )
                        })}

                        <div id="illustratorDiv" className="input-field hide">
                            <i className="material-icons prefix"></i>
                            <input type="text" id="bookIllustrator" value={illustrator} onChange={(e) => onFieldChange(e)} />
                            <label htmlFor="bookIllustrator">Illustrator</label>
                        </div>
                        <div id="translatorDiv" className="input-field hide">
                            <i className="material-icons prefix"></i>
                            <input type="text" id="bookTranslator" value={translator} onChange={(e) => onFieldChange(e)} />
                            <label htmlFor="bookTranslator">Translator</label>
                        </div>
                        <div id="prefaceDiv" className="input-field hide">
                            <i className="material-icons prefix"></i>
                            <input type="text" id="bookPreface" value={preface} onChange={(e) => onFieldChange(e)} />
                            <label htmlFor="bookPreface">Preface</label>
                        </div>
                        <div className="input-field">
                            <i className="material-icons prefix"></i>
                            <input type="text" id="bookGenre" value={genre} onChange={(e) => onFieldChange(e)} />
                            <label htmlFor="bookGenre">Genre</label>
                        </div>
                        <div className="input-field">
                            <i className="material-icons prefix"></i>
                            <input type="text" id="bookLanguage" value={language} onChange={(e) => onFieldChange(e)} />
                            <label htmlFor="bookLanguage">Language</label>
                        </div>
                        <div className="input-field">
                            <i className="material-icons prefix"></i>
                            <input type="text" id="bookPublishYear" value={publishYear} onChange={(e) => onFieldChange(e)} />
                            <label htmlFor="bookPublishYear">Publish year</label>
                        </div>
                        <div className="input-field">
                            <i className="material-icons prefix"></i>
                            <input type="text" id="bookCollection" value={collection} onChange={(e) => onFieldChange(e)} />
                            <label htmlFor="bookCollection">Collection</label>
                        </div>
                        <div className="input-field">
                            <i className="material-icons prefix">message</i>
                            <textarea id="bookDescription" className="materialize-textarea" value={description} onChange={(e) => onFieldChange(e)} ></textarea>
                            <label htmlFor="bookDescription">Description</label>
                        </div>
                        <div className="input-field center">
                            <button id="CreateBookButton" className="btn" disabled>Submit</button>
                        </div>
                        <p className="error pink-text center-align">{error}</p>
                    </div>
                </div>
            </form>
        </div>
    )
}

export default CreateBook;