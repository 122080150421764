import React, { useContext, useEffect } from 'react';
import { Link } from 'react-router-dom';
import SignedInLinks from './SignedInLinks';
import SignedOutLinks from './SignedOutLinks';
import SignedInMobile from './SignedInMobile';
import SignedOutMobile from './SignedOutMobile';
import { UserContext } from '../../contexts/userContext'; 
import M from 'materialize-css';

const Navbar = () => {
    useEffect(() => {
        let elements = document.querySelectorAll('.sidenav');
        M.Sidenav.init(elements );
    },[])

    const { user } = useContext(UserContext);
    const links = user ? <SignedInLinks/> : <SignedOutLinks />;
    const mobileLinks = user ? <SignedInMobile/> : <SignedOutMobile />;
    return (
        <div>
            <div className="navbar-fixed">
                <nav className="nav-wrapper teal">
                    <div className="container">
                        <Link to='/' className="brand-logo hide-on-med-and-down">What Books do I have?</Link>
                        <Link to='/' className="brand-logo hide-on-large-only">What Books?</Link>
                        <a href="#!" className="sidenav-trigger" data-target="mobile-links">
                            <i className="material-icons">menu</i>
                        </a>
                        <ul className="right hide-on-med-and-down">
                        {links}
                        </ul>
                    </div>
                </nav>
            </div>
            <ul className="sidenav" id="mobile-links">
            {mobileLinks}
            </ul>
        </div>
    )
}

export default Navbar;