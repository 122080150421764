
export async function getPageImages(name)  {
    var url = "https://en.wikipedia.org/w/api.php"; 

    var params = {
        action: "query",
        prop: "pageimages",
        titles: name,
        pithumbsize: "500",
        pilicense: "any",
        format: "json"
    };

    var imagens = [];

    url = url + "?origin=*";
    Object.keys(params).forEach((key) => {url += "&" + key + "=" + params[key];});
    
    await fetch(url)
        .then(function(response){return response.json();})
        .then(function(response) {
            var pages = response.query.pages;
            for (var page in pages) {
                if (page === "-1") {
                    imagens.push('/images/Nophoto.jpg');
                } else {
                    imagens.push(pages[page].thumbnail.source);
                }
                
            }
        })
        .catch((error) => {
            console.log(error);
            imagens.push('/images/Nophoto.jpg');
        });
    return imagens[0];
}

