import React, { useState, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { UserContext } from '../../contexts/userContext'; 


const SignUp = () => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [password2, setPassword2] = useState('');
    const [displayName, setDisplayName] = useState('');
    const [error, setError] = useState('');
    const history = useHistory();
    const { userCreateEmail } = useContext(UserContext);
    
    const handleSubmit = (e) => {
        e.preventDefault();
        if (password === password2) {
            userCreateEmail( email, password, displayName)
            .then( (newUser) => {
                setError('');
                setEmail('');
                setPassword('');
                setPassword2('');
                setDisplayName('');
                document.getElementById('signupForm').reset();
                history.push("/");
            })
            .catch( (err) => {
                setError(err.message);
            });

        } else {
            setError('password do not match');
        }
    }
    return (
        <div className="container">
            <form id="signupForm" onSubmit={handleSubmit} className="white">
                <h5 className="grey-text text-farken-3">Sign Up</h5>
                <div className="input-field">
                    <input type="email" id="email" value={email}
                    onChange={(e) => setEmail(e.target.value)} required/>
                    <label htmlFor="email">Email</label>
                </div>
                <div className="input-field">
                    <input type="password" id="password" value={password}
                    onChange={(e) => setPassword(e.target.value)} required/>
                    <label htmlFor="password">Password</label>
                </div>
                <div className="input-field">
                    <input type="password" id="password2" value={password2}
                    onChange={(e) => setPassword2(e.target.value)} required/>
                    <label htmlFor="password2">Confirm Password</label>
                </div>
                <div className="input-field">
                    <input type="text" id="name" value={displayName}
                    onChange={(e) => setDisplayName(e.target.value)}/>
                    <label htmlFor="name">Your name</label>
                </div>
                <div className="input-field">
                    <button className="btn">Sign Up</button>
                </div>
                <p className="error pink-text center-align">{error}</p>
            </form>
        </div>
    )
}

export default SignUp;