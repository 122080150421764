import firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/auth';
import 'firebase/functions';
import 'firebase/storage';
import "firebase/performance";

// Your web app's Firebase configuration
const firebaseConfig = {
apiKey: "AIzaSyBmblC48b9pXYISosuSt7cTCN1iJQnE2QI",
authDomain: "whatbooks-89cf8.firebaseapp.com",
databaseURL: "https://whatbooks-89cf8.firebaseio.com",
projectId: "whatbooks-89cf8",
storageBucket: "whatbooks-89cf8.appspot.com",
messagingSenderId: "1018706394397",
appId: "1:1018706394397:web:3a897323e58db217c2f5f4",
measurementId: "G-ETYT5B1R42"
};

// Initialize Firebase
firebase.initializeApp(firebaseConfig);
export const fb = firebase;
export const auth = firebase.auth();
export const db = firebase.firestore();
export const storage = firebase.storage();
export const perf = firebase.performance();

const googleProvider = new firebase.auth.GoogleAuthProvider();

export const signInWithGoogle = () => {
  console.log('signInWithGoogle');
  auth.signInWithRedirect(googleProvider)
    .then( (res) => {
      console.log('signInWithPopup');
      console.log(res);
    })
    .catch( (err) => {
      console.log(err);
    })
};
