import React, { createContext, useState, useEffect, useContext } from "react";
import { db } from "../components/Others/firebase";
import { UserContext } from './userContext'; 

export const BooksContext = createContext({
    books: []
});

const BooksContextProvider = (props) => {
    const [state, setState] = useState({books: []});
    // const [booksOrder, setBooksOrder] = useState(1);
    const { user, prefs } = useContext(UserContext);

    useEffect( () => {
        if (user) {
            console.log('mounting books...');
            // we need to unsubscribe callback()
            const unsubscribe = db.collection("books")
            .where("user", "==", user)
            .orderBy('title').onSnapshot((snapshot) => {
                const newBooks = snapshot.docs.map((doc) => ({
                    id: doc.id,
                    ...doc.data()
                }));

                if (prefs.booksOrder) {
                    if (prefs.booksOrder === 1) {
                        newBooks.sort(compareTitle)
                    } else {
                        newBooks.sort(compareAuthor)
                    }
                }

                setState({books: newBooks});
            })
            return () => {
                console.log('unmounting books');
                unsubscribe();
            }

        } else {
            return;
        }

    }, [user, prefs.booksOrder]);
    
    const compareTitle = (a, b) => {
        const titleA = a.title.toUpperCase();
        const titleB = b.title.toUpperCase();

        let comparison = 0;
        if (titleA > titleB ) {
            comparison = 1;
        } else {
            comparison = -1;
        }
        return comparison;
    }

    const compareAuthor = (a, b) => {
        const authorA = a.authors[0].toUpperCase() + a.title.toUpperCase();
        const authorB = b.authors[0].toUpperCase() + b.title.toUpperCase();

        let comparison = 0;
        if (authorA > authorB ) {
            comparison = 1;
        } else {
            comparison = -1;
        }
        return comparison;
    }

    const bookSetOrder = (bookOrder) => {
        let newBookList = [...state.books];

        if (bookOrder === 1) {
            newBookList.sort(compareTitle)
        } else {
            newBookList.sort(compareAuthor)
        }
        setState({books: newBookList});
    }
    
    const bookDelete = (bookID, callback) => {
        db.collection("books").doc(bookID).delete()
        .then(() => {
            callback(undefined);
        })
        .catch((err) => {
            callback(err);
        });
    }

    const bookExist = (bookTitle) => {
        const bookList = state.books;
        const result = bookList.find((book) => book.title === bookTitle);
        const returnValue = (result) ? result.id : false;

        return returnValue;
    }

    const bookISBNExist = (bookISBN) => {
        const bookList = state.books;
        const result = bookList.find((book) => book.ISBN === bookISBN);
        const returnValue = (result) ? result.id : false;

        return returnValue;
    }

    return (
        <BooksContext.Provider value={{...state, 
                                       bookExist: bookExist, 
                                       bookISBNExist: bookISBNExist, 
                                       bookDelete: bookDelete,
                                       bookSetOrder: bookSetOrder}}>
            {props.children}
        </BooksContext.Provider>
    );
}

export default BooksContextProvider;
