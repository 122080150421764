import React from 'react';

const Footer = () => {
    return (
        <footer className="page-footer teal darken-3">
            {/* <div className="container">
                <div className="row">
                    <div className="col s12 l6">
                        <h5>Made by me</h5>
                        <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Eos cum, doloribus asperiores ut dolore laudantium veritatis eveniet hic possimus, quidem consectetur? Sapiente quam vel voluptatum dicta animi non quidem amet.</p>
                    </div>
                    <div className="col s12 l4 offset-l2">
                        <h5>Connect</h5>
                        <ul>
                            <li><a href="/" className="grey-text text-lighten-3">Facebook</a></li>
                            <li><a href="/" className="grey-text text-lighten-3">Twiter</a></li>
                            <li><a href="/" className="grey-text text-lighten-3">Linked In</a></li>
                            <li><a href="/" className="grey-text text-lighten-3">Intagram</a></li>
                        </ul>
                    </div>
                </div>
            </div> */}
            <div className="footer-copyright teal darken-4">
                <div className="container center-align">&copy;2020 WhatBooksDoIHave</div>
                <div className="container">
                    <span><a href="/" className="grey-text">About us</a></span>
                    <span><a href="/" className="grey-text">Cookies</a></span>
                    <span><a href="/" className="grey-text">Privacy Policy</a></span>
                </div>
            </div>
        </footer>
    )
}

export default Footer;