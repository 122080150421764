import React, { useState, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { UserContext } from '../../contexts/userContext'; 


const LogIn = () => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState('');
    const history = useHistory();
    const { userLoginEmail } = useContext(UserContext);

    const handleSubmit = (e) => {
        e.preventDefault();
        userLoginEmail( email, password)
            .then((userAuth) => {
                setEmail('');
                setPassword('');
                setError('');
                document.getElementById('loginForm').reset();
                history.push("/");
            })
            .catch((err) => {
                // Handle Errors
                var errorMessage = err.message;
                setError(errorMessage);
            })
    }

    return (
        <div className="container">
            <form id="loginForm" onSubmit={handleSubmit} className="white">
                <h5 className="grey-text text-farken-3">Log In</h5>
                <div className="input-field">
                    <input type="email" id="email" onChange={(e) => setEmail(e.target.value)} required/>
                    <label htmlFor="email">Email</label>
                </div>
                <div className="input-field">
                    <input type="password" id="password" onChange={(e) => setPassword(e.target.value)} required/>
                    <label htmlFor="password">Password</label>
                </div>
                <div className="input-field">
                    <button className="btn">Log In</button>
                </div>
                <p className="error pink-text center-align">{error}</p>
            </form>
        </div>
    )
}

export default LogIn;