// import React from 'react';

export const Countries = (name, language) => {
    const countriesData = 
    [{
        "en": "Portugal",
        "es": "Portugal",
        "pt": "Portugal",
        "code": "PT"
    }, {
        "en": "Spain",
        "es": "España",
        "pt": "Espanha",
        "code": "ES"
    }, {
        "en": "Angola",
        "es": "Angola",
        "pt": "Angola",
        "code": "AN"
    }, {
        "en": "Argentina",
        "es": "Argentina",
        "pt": "Argentina",
        "code": "AR"
    }, {
        "en": "Australia",
        "es": "Australia",
        "pt": "Austrália",
        "code": "AU"
    }, {
        "en": "Austria",
        "es": "Austria",
        "pt": "Áustria",
        "code": "AT"
    }, {
        "en": "Belgium",
        "es": "Bélgica",
        "pt": "Bélgica",
        "code": "BE"
    }, {
        "en": "Bolivia",
        "es": "Bolivia",
        "pt": "Bolívia",
        "code": "BO"
    }, {
        "en": "Brazil",
        "es": "Brasil",
        "pt": "Brasil",
        "code": "BR"
    }, {
        "en": "Bulgaria",
        "es": "Bulgaria",
        "pt": "Bulgária",
        "code": "BG"
    }, {
        "en": "Canada",
        "es": "Canadá",
        "pt": "Canada",
        "code": "CA"
    }, {
        "en": "Cape Verde",
        "es": "Cabo Verde",
        "pt": "Cabo Verde",
        "code": "CV"
    }, {
        "en": "Chile",
        "es": "Chile",
        "pt": "Chile",
        "code": "CL"
    }, {
        "en": "China",
        "es": "China",
        "pt": "China",
        "code": "CN"
    }, {
        "en": "Cuba",
        "es": "Cuba",
        "pt": "Cuba",
        "code": "CU"
    }, {
        "en": "Czech Republic",
        "es": "República Checa",
        "pt": "República Checa",
        "code": "CZ"
    }, {
        "en": "Denmark",
        "es": "Dinamarca",
        "pt": "Dinamarca",
        "code": "DK"
    }, {
        "en": "France",
        "es": "Francia",
        "pt": "França",
        "code": "FR"
    }, {
        "en": "Germany",
        "es": "Alemania",
        "pt": "Alemanha",
        "code": "DE"
    }, {
        "en": "Greece",
        "es": "Grecia",
        "pt": "Grécia",
        "code": "GR"
    }, {
        "en": "Italy",
        "es": "Italia",
        "pt": "Itália",
        "code": "IT"
    }, {
        "en": "Mexico",
        "es": "Mexico",
        "pt": "México",
        "code": "MX"
    }, {
        "en": "Mozambique",
        "es": "Mozambique",
        "pt": "Moçambique",
        "code": "MZ"
    }, {
        "en": "Netherlands",
        "es": "Países bajos",
        "pt": "Holanda",
        "code": "NL"
    }, {
        "en": "Russia",
        "es": "Rusia",
        "pt": "Rússia",
        "code": "RU"
    }, {
        "en": "Sweden",
        "es": "Suecia",
        "pt": "Suécia",
        "code": "SE"
    }, {
        "en": "Switzerland",
        "es": "Suiza",
        "pt": "Suíça",
        "code": "CH"
    }, {
        "en": "Turkey",
        "es": "Turquía",
        "pt": "Turquia",
        "code": "TR"
    }, {
        "en": "United Kingdom",
        "es": "Reino Unido",
        "pt": "Reino Unido",
        "code": "GB"
    }, {
        "en": "England",
        "es": "Inglaterra",
        "pt": "Inglaterra",
        "code": "GB"
    }, {
        "en": "Scotland",
        "es": "Escocia",
        "pt": "Escócia",
        "code": "GB"
    }, {
        "en": "United States of America",
        "es": "Estados Unidos de América",
        "pt": "Estados Unidos da América",
        "code": "US"
    }, {
        "en": "USA",
        "es": "EUA",
        "pt": "EUA",
        "code": "US"
    }]

    language = language ? language : 'en';
    let country = '';
    switch (language) {
        case 'pt': 
            country = countriesData.find(country => (country.pt === name));
            break;
        case 'es': 
            country = countriesData.find(country => (country.es === name));
            break;
        default: 
            country = countriesData.find(country => (country.en === name));
    }

    const code = (country) ? country.code : '';

    return code;
}
