import xml2js from 'xml2js';
import axios from 'axios';

export async function getGoodReadsBook(bookISBN)  {
    var url = "https://cors-anywhere.herokuapp.com/https://www.goodreads.com/book/isbn/"; 

    var bookInfo = '';

    // url += bookISBN + "?format=json&user_id=35814094";
    url += bookISBN + "?key=cor6ywkrsZ6dIOoVhiETzQ";
    console.log(url);

    // await fetch(url)
    await axios.get(url)
    // .then(function(response){return response.json();})
    .then(function(response) {
        console.log(response);
        xml2js.parseString( response.data, function (err, result) {
            console.log("XML-JSON Parse")
            console.log(result); // Prints JSON object!
            bookInfo = (result.GoodreadsResponse.book[0]);
          });
        // console.log(response);
        // const keys = Object.keys(response);
        // bookInfo = (response[keys[0]]);
    })
    .catch((error) => {
        console.log(error);
    });

    return bookInfo;
}