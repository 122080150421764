import React, { useContext, useState, useEffect } from 'react';
import { AuthorsContext } from '../../contexts/authorsContext'; 
import { UserContext } from '../../contexts/userContext'; 
import { Countries } from '../Others/Countries';
import { WorldMap } from "react-svg-worldmap"

const AuthorsMap = () => {
    const { authors } = useContext(AuthorsContext);
    const [countriesData, setCountriesdata] = useState([]);
    const { language } = useContext(UserContext);

    useEffect(() => {
        console.log('initial authorsMap load');
        let countryList = [];
        authors.forEach( (author) => {
            let entryFound = false;
            let tempCountry = {
                country: Countries(author.country, language),
                value: 1
            };
            for (let item of countryList) {
                if (item.country === tempCountry.country) {
                    item.value++;
                    entryFound = true;
                    break;
                }
            }
            if (!entryFound) {
                countryList.push(tempCountry)
            }
        })
        setCountriesdata(countryList);
        // console.log(countryList);
    }, [authors, language])


    return (
        <div className="authormap container">
            <WorldMap color="teal" tooltipBgColor={"green"} title="Authors" borderColor={"green"} frameColor={"green"} valueSuffix="authors" size="xl" data={countriesData} frame={false}/>
        </div>
    )
}

export default AuthorsMap;