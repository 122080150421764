import React, {useContext, useState, useEffect} from 'react';
import { NavLink, Link } from 'react-router-dom';
import { BooksContext } from '../../contexts/booksContext'; 
import { AuthorsContext } from '../../contexts/authorsContext'; 
import { UserContext } from '../../contexts/userContext'; 
import { useParams } from 'react-router-dom';
import M from 'materialize-css';

// const BooksList = (props) => {
const BooksList = () => {
    const { books, bookDelete, bookSetOrder } = useContext(BooksContext);
    const { authorExist, authorRemoveBook } = useContext(AuthorsContext);
    const [bookList, setBookList] = useState([]);
    const [book, setBook] = useState({});
    const [order, setOrder] = useState(1);
    const [searchString, setSearchString] = useState('');
    let prevAuthor = '';
    const [editLink, setEditLink] = useState('');
    const { id } = useParams();
    const { prefs } = useContext(UserContext);

    useEffect(() => {
        console.log('initial load');
        setBookList(books);
        if (prefs.booksOrder) {
            setOrder(prefs.booksOrder);
        }
        if (id) {
            setBookList(books.filter((book) => {
                return book.authors.some((author) => author.toUpperCase().includes(id.toUpperCase()))
            }))
        }
    },[books, prefs, id])

    const deleteBook = () => {
        // console.log("deleting...");
        // console.log(book);
        const oldTitle = book.title;
        let authorID = '';
        book.authors.forEach( (author) => {
            authorID = authorExist(author);
            authorRemoveBook(authorID, book.id, (err) => {
                if (err) {
                    console.log('error removing book')
                    console.log(err)
                } else {
                    bookDelete(book.id, (err) => {
                        if (err) {
                            console.log('error delete book');
                            console.log(err);
                        } else {
                            M.toast({html: oldTitle + ' deleted'}); 
                        }
                    })
                }
            })
        })
    }

    const setSortOrder = (bookOrder) => {
        setOrder(bookOrder)
        prefs.booksOrder = bookOrder;
        bookSetOrder(bookOrder)
        setBookList(books);
    }

    const onChangeOrder = (e) => {
        // setOrder(1 + e.target.checked);
        if (e.target.checked) {
            setSortOrder(2);
        } else {
            setSortOrder(1);
        }
    }

    const filterBooks = (searchString) => {

        let newBookList = books.filter((book) => {
            return ( 
                book.title.toUpperCase().includes(searchString.toUpperCase())
                ||
                book.authors.some((author) => author.toUpperCase().includes(searchString.toUpperCase()))
                ||
                (book.illustrator && book.illustrator.toUpperCase().includes(searchString.toUpperCase()) )
                ||
                (book.description && book.description.toUpperCase().includes(searchString.toUpperCase()) )
            )
        });
        setBookList(newBookList);
}

    const onSearchChange = (e) => {
        setSearchString(e.target.value);

        filterBooks(e.target.value);
    }

    const handleClick = (book) => {
        let elements = document.querySelectorAll('.modal');
        M.Modal.init(elements);
        let matimage = document.querySelectorAll('.materialboxed');
        M.Materialbox.init(matimage);

        setEditLink('/books/create/'+book.id);
        setBook(book);
        let bookModal = document.getElementById('modal-book-detail');
        let moreInfoHTML = '';
        document.getElementById('ModalBookTitle').innerText = book.title;
        document.getElementById('ModalBookSubTitle').innerText = (book.subtitle) ? book.subtitle : '';
        let authorList = '';
        book.authors.forEach( (author, i) => {
            authorList = authorList + (i ? ', ':'');
            authorList = authorList + author;
        })
        document.getElementById('ModalBookAuthor').innerText = authorList;
        document.getElementById('ModalBookPublisher').innerText = book.publisher;
        document.getElementById('ModalBookISBN').innerText = book.ISBN;
        document.getElementById('ModalBookLanguage').innerText = book.language;
        if (book.genre) {
            moreInfoHTML = moreInfoHTML + 'Genre: <b><span>' + book.genre + '</span></b><br/>';
        }
        if (book.collection) {
            moreInfoHTML = moreInfoHTML + 'Collection: <b><span>' + book.collection + '</span></b><br/>';
        }
        if (book.illustrator) {
            moreInfoHTML = moreInfoHTML + 'Illustrator: <b><span>' + book.illustrator + '</span></b><br/>';
        }
        if (book.translator) {
            moreInfoHTML = moreInfoHTML + 'Translator: <b><span>' + book.translator + '</span></b><br/>';
        }
        if (book.goodReadsURL) {
            moreInfoHTML = moreInfoHTML + "GoodReads: <span> <a href='" + book.goodReadsURL + "'>" + book.goodReadsID + "</a></span><br/>";
        }
        if (book.openLibraryURL) {
            moreInfoHTML = moreInfoHTML + "OpenLibrary: <span> <a href='" + book.openLibraryURL + "'>" + book.openLibraryID + "</a></span><br/>";
        }
        // document.getElementById('ModalBookGenre').innerText = book.genre;
        document.getElementById('ModalBookInfo').innerHTML = moreInfoHTML;
        document.getElementById('ModalBookDescription').innerHTML = book.description;

        if (book.cover === '') {
            document.getElementById('ModalBookCover').src = 'images/nocover.png';
        } else {
            if (book.cover.startsWith("http")) {
                document.getElementById('ModalBookCover').src = book.cover;
            } else {
                document.getElementById('ModalBookCover').src = 'covers/' + book.cover;
            }
        }
        document.getElementById('ModalBookCover').alt = book.title;

        M.Modal.getInstance(bookModal).open();
    };

    return (
        <div className="bookslists container">
        {/* <BookModalDetail /> */}
            <div id="modal-book-detail" className="modal">
                <div className="modal-content">
                    <div className="row">
                        <div className="col s12 m4">
                            <img id="ModalBookCover" src="images/nocover.png" alt="" width="100%" height="100%"/>
                        </div>
                        <div className="col s12 m6">
                            <h4 id="ModalBookTitle">Title...</h4>
                            <h5 id="ModalBookSubTitle">Subtitle...</h5>
                            <h5 id="ModalBookAuthor">Author...</h5>
                            Publisher: <b><span id="ModalBookPublisher">Publisher...</span></b><br/>
                            ISBN: <b><span id="ModalBookISBN">ISBN...</span></b><br/>
                            Language: <b><span id="ModalBookLanguage">Language...</span></b><br/>
                            <div id="ModalBookInfo">
                            Genre: <b><span id="ModalBookGenre">Genre...</span></b>
                            </div>
                        </div>
                        <div className="col s12 m12">
                            <p id="ModalBookDescription">Notes...</p>
                        </div>
                    </div>
                </div>
            {/* <div className="modal-footer grey lighten-2"> */}
                <div id="ModalBookButtons" className="card-action grey lighten-2">
                    <NavLink to={editLink} className="modal-close waves-effect btn-flat">Edit</NavLink>
                    <Link to="#!" className="modal-close waves-effect btn-flat" onClick={deleteBook}>Delete</Link>
                    <Link to="#!" className="modal-close waves-effect btn-flat">Lend</Link>
                </div>

            </div>
        {/* <BookModalDetail /> */}
            <div className="fixed-action-btn">
                <NavLink to='/books/create' className="btn-large btn-floating waves-effect waves-light"><i className="material-icons">add</i></NavLink>
            </div>
            {/* search bar */}
            <div className="container">
                 <br></br>
            </div>
            <nav>
                <div className="nav-wrapper grey lighten-4">
                    <div className="input-field">
                        <input id="search" type="search" value={searchString} onChange={(e) => onSearchChange(e)}/>
                        <label className="label-icon" htmlFor="search"><i className="material-icons grey-text">search</i></label>
                        <i className="material-icons">close</i>
                    </div>
                </div>                
            </nav>
            <div className="switch">
                <label>
                Titles
                <input type="checkbox" checked={order-1} onChange={(e) => onChangeOrder(e)} />
                <span className="lever"></span>
                Authors
                </label>
            </div>
            
            <ul className="collection with-header">
                {bookList && bookList.map(book => {
                    let authorHeader = null;
                    const coverURL = (book.cover.startsWith("http")) 
                        ? book.cover 
                        : (book.cover) ? 'covers/' + book.cover : '/images/nocover.png';

                    if (order === 2 && book.authors[0] !== prevAuthor) {
                        if (book.authors[0]) {
                            prevAuthor = book.authors[0]
                            authorHeader = 
                            (
                                <React.Fragment>
                                    <li className="collection-header">
                                        <h5>{prevAuthor}</h5>
                                    </li>
                                </React.Fragment>
                            );
                        }
                    }
                    return (
                        <div key={book.id}>
                            {authorHeader}
                            <a className="collection-item " href="#!" key={book.id} data-target={book} onClick={(e) => handleClick(book)}>
                                <div className="row">
                                    <div className="col s12 m2">
                                        <img src={coverURL} alt="" className="responsive-img" height="80" width="80"/>
                                    </div>
                                    <div className="col s12 m10">
                                        <h5>{book.title}</h5>
                                        <span>{book.subtitle}</span>
                                        {book.authors && book.authors.map(author => {
                                            const newKey = book.id + author;
                                            return (
                                                <p key={newKey}>{author}</p>
                                            )
                                        })}
                                    </div>
                                </div>
                            </a>
                        </div>
                    )
                })}
            </ul>
        </div>    
    );
}


export default BooksList;