import React, { useContext, useState } from 'react';
import { auth, storage } from "../Others/firebase";
import { useHistory } from 'react-router-dom';
import { UserContext } from '../../contexts/userContext'; 
import { AuthorsContext } from '../../contexts/authorsContext'; 
import { BooksContext } from '../../contexts/booksContext'; 
import M from 'materialize-css';

const Profile = () => {
    const { user, email, displayName, photoURL, language, prefs, setUserLanguage, setUserPhoto, userDelete } = useContext(UserContext);
    const { books } = useContext(BooksContext);
    const { authors } = useContext(AuthorsContext);
    const [photoPreview, setPhotoPreview] = useState('');
    const [imageFile, setImageFile] = useState({});
    const [progress, setProgress] = useState();
    const [fileError, setFileError] = useState('');
    const [emailConfirmation, setEmailConfirmation] = useState('');
    const history = useHistory();

    const uploadFile = (file, callback) => {
        if (file !== '') {
            const uploadTask = storage.ref(`/users/${user}/images/${file.name}`).put(file);

            uploadTask.on("state_changed", 
                snapshot => {
                    // task progress
                    const uploadProgress = Math.round( (snapshot.bytesTransferred / snapshot.totalBytes) * 100 );
                    setProgress({width: uploadProgress + "%"});
                },
                err => {
                    // error function
                    callback(err, undefined);
                },
                () => {
                    // complete function
                    storage.ref(`/users/${user}/images/`).child(file.name)
                        .getDownloadURL().then( (url) => {
                            setProgress('');
                            if (callback) {
                                callback(undefined, url);
                            }
                        })
                })
        }
    }

    const uploadProfilePhoto = () => {
        uploadFile(imageFile, (err, url) => {
            if (err) {
                setFileError(err);
            } else {
                if (url) {
                    setPhotoPreview(url);
                    // document.getElementById('bookCoverFile').value = "";
                    // document.getElementById('bookCoverFileWrapper').value = "";
                    setUserPhoto(url);
                    M.updateTextFields();
                }
            }
        });
    }

    const onFileChange = (e) => {
        const image = e.target.files[0];
        if (image) {
            const fileType = image["type"];
            const validImageTypes = ["image/gif", "image/jpeg", "image/png"];
            if (!validImageTypes.includes(fileType)) {
                setFileError('invalid image file');
                document.getElementById('uploadPhotoButton').classList.add("disabled");
            } else {
                if (image.size > 16384) {
                    setFileError('image too big');
                    document.getElementById('uploadPhotoButton').classList.add("disabled");
                } else {
                    setFileError('');
                    // setPhotoPreview('');
                    setImageFile(image);
                    document.getElementById('uploadPhotoButton').classList.remove("disabled");                }
            }
        } else {
            setFileError('');
            document.getElementById('uploadPhotoButton').classList.add("disabled");
        }
    }

    const showChangeProfilePhoto = () => {
        let elements = document.querySelectorAll('.modal');
        M.Modal.init(elements);
        setPhotoPreview(photoURL ? photoURL : "https://p2.trrsf.com/image/fget/cf/460/0/images.terra.com/2013/03/14/einstein-arquivo-afp.jpg");
        M.updateTextFields();
        let photoModal = document.getElementById('modal-photo-detail');
        M.Modal.getInstance(photoModal).open();
    }

    const changeLanguage = (newLanguage) => {
        console.log(newLanguage);
        setUserLanguage(newLanguage);
    }

    const showChangeLanguage = () => {
        let elements = document.querySelectorAll('.modal');
        M.Modal.init(elements);
        let languageModal = document.getElementById('modal-language-detail');
        M.Modal.getInstance(languageModal).open();
    }

    const confirmDeleteUser = () => {
        userDelete(user)
            .then( () => {
                history.push("/");
            })
            .catch( (err) => {
                console.log('error:', err);
            })
    }

    const changeEmailConfirmation = (evalue) => {
        setEmailConfirmation(evalue);
        if (evalue === email) {
            document.getElementById('confirmDeleteButton').classList.remove("disabled");
        } else {
            document.getElementById('confirmDeleteButton').classList.add("disabled");
        }
    }

    const showDeleteUser = () => {
        let elements = document.querySelectorAll('.modal');
        M.Modal.init(elements);
        let deleteuserModal = document.getElementById('modal-delete-detail');
        M.Modal.getInstance(deleteuserModal).open();
    }

    const validateEmail = () => {
        auth.currentUser.sendEmailVerification()
        .then( () => {
            M.toast({html: 'Email sent'});
        })
        .catch( (err) => {
            M.toast({html: 'Opsss...'});
            console.log(err);
        })
    }

    const profilePhoto = photoURL ? photoURL : "https://p2.trrsf.com/image/fget/cf/460/0/images.terra.com/2013/03/14/einstein-arquivo-afp.jpg";
    let languageDisplay = '';
    switch (language) {
        case 'pt':
            languageDisplay = 'Português';
            break;
        case 'es':
            languageDisplay = 'Español';
            break;
        default:
            languageDisplay = 'English';
    }

    return (
        <div className="profile">
            <div id="modal-photo-detail" className="modal">
                <div className="modal-content">
                    <div className="row">
                        <div className="col s12 m3">
                            <img className="" width="100px" src={photoPreview} alt=""/>
                        </div>
                        <div className="col s12 m9">
                            <div className="input-field">
                                <input type="text" id="photoProfileURL" readOnly defaultValue={profilePhoto} />
                                <label htmlFor="photoProfileURL">URL</label>
                            </div>
                            <div className="file-field input-field">
                                <div className="btn">
                                    <span>File</span>
                                    <input type="file" name="image" id="profilePhotoFile" onChange={(e) => onFileChange(e)}/>
                                </div>
                                <div className="file-path-wrapper">
                                    <input className="file-path validate" type="text" placeholder="profile photo" />
                                </div>
                            </div>
                            <p className="error pink-text center-align">{fileError}</p>
                            {progress && 
                                <div className="progress">
                                    <div className="determinate" style={progress}></div>
                                </div>
                            }
                        </div>
                    </div>
                    <br/>
                    <br/>
                    <div id="ModalPhotoButtons" className="card-action grey lighten-4 grey-text">
                        <a href="#!" onClick={uploadProfilePhoto} id="uploadPhotoButton" className="modal-close waves-effect btn disabled">Upload</a>
                    </div>
                </div>
            </div>
            <div id="modal-language-detail" className="modal">
                <div className="modal-content">
                    <div className="row">
                        <div className="col s4 m4">
                            <button className="modal-close btn-flat" onClick={() => changeLanguage('en')}>
                            <img src="images/Flag_of_The_United_Kingdom.svg" alt="" className="hoverable responsive-img"/>
                            </button>
                        </div>
                        <div className="col s4 m4">
                            <button className="modal-close btn-flat" onClick={() => changeLanguage('pt')}>
                            <img src="images/Flag_of_Portugal.svg" alt="" className="hoverable responsive-img"/>
                            </button>
                        </div>
                        <div className="col s4 m4">
                            <button className="modal-close btn-flat" onClick={() => changeLanguage('es')}>
                            <img src="images/Flag_of_Spain.svg" alt="" className="hoverable responsive-img"/>
                            </button>
                        </div>
                    </div>
                    <br/>
                    <br/>
                </div>
            </div>
            <div id="modal-delete-detail" className="modal">
                <div className="modal-content">
                    <div className="row">
                        <div className="col s12 m12">
                            <h5>This action will erase all user data.</h5>
                            Enter you email to confirm this action

                            <div className="input-field">
                                <input type="text" value={emailConfirmation} onChange={(e) => changeEmailConfirmation(e.target.value)} required />
                                <label htmlFor="emailconfirmationbox">Confirmation</label>
                            </div>
                            <div id="ModalDeleteButtons" className="card-action grey lighten-4 grey-text">
                                <a href="#!" onClick={confirmDeleteUser} id="confirmDeleteButton" className="modal-close waves-effect btn disabled">Delete</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <br></br>
            <br></br>
            <div className="container">
                <div className="row">
                    <div className="col s12">
                        <div className="card-panel hoverable z-depth-5">
                            <div className="row">
                                <div className="col s12 m2">
                                    <img className="circle" width="100px" src={profilePhoto} alt=""/>
                                </div>
                                <div className="col s12 m6">
                                    {/* <p><b>user:</b> {user} </p> */}
                                    <p><b>Name:</b> {displayName} </p>
                                    <p><b>Email:</b> {email} 
                                    {auth.currentUser.emailVerified
                                        ? <i className="material-icons green-text">verified_user</i>
                                        : <i className="material-icons yellow-text accent-4">info_outline</i>
                                    }</p>
                                    <p><b>Language:</b> {languageDisplay} </p>
                                    <p><b>Theme:</b> {prefs.theme} </p>
                                </div>
                                <div className="col s12 m3">
                                    <p><b>Total Authors:</b> {authors.length} </p>
                                    <p><b>Total Books:</b> {books.length} </p>
                                </div>
                            </div>
                            <a href="#!" onClick={showChangeProfilePhoto} className="btn-flat">Change Photo</a>
                            <a href="#!" onClick={showChangeLanguage} className="btn-flat">Change Language</a>
                            <a href="#!" className="btn-flat disabled">change Theme</a>
                            {!auth.currentUser.emailVerified && <a href="#!" onClick={validateEmail} className="btn-flat green-text">Verify email</a>}
                            <a href="#!" onClick={showDeleteUser} className="btn-flat">Delete user</a>
                        </div>
                    </div>
                </div>
            </div>
            <br></br>
        </div>
        // <div className="image" style={{backgroundImage: 'images/t-steele-x-mIAFbAL-o-unsplash.jpg'}}>
        //     teste
        //         {/* <img className="" width="100%" src="images/t-steele-x-mIAFbAL-o-unsplash.jpg" alt=""/> */}
        // </div>
    )
}

export default Profile;