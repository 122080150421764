import React, { useState, useEffect, useContext } from 'react';
import M from 'materialize-css';
import { UserContext } from '../../contexts/userContext'; 

const SignUpModal = () => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [password2, setPassword2] = useState('');
    const [displayName, setDisplayName] = useState('');
    const [error, setError] = useState('');
    let signupModal = document.getElementById('modal-signup');
    const { userCreateEmail, userLoginGoogle } = useContext(UserContext);

    useEffect(() => {
        M.Modal.init(signupModal);
    },[signupModal])

    const handleSubmit = (e) => {
        e.preventDefault();
        if (password === password2) {
            userCreateEmail( email, password, displayName)
                .then( (newUser) => {
                    setError('');
                    setEmail('');
                    setPassword('');
                    setPassword2('');
                    setDisplayName('');
                    document.getElementById('signupForm').reset();
                    M.Modal.getInstance(signupModal).close();
                })
                .catch( (err) => {
                    setError(err.message);
                });
        } else {
            setError('password do not match');
        }
    }

    const handleSigninGoogle = () => {
        userLoginGoogle()
    }

    return (
        <div id="modal-signup" className="modal">
            <div className="modal-content">
                <h4>Signup</h4>
                <div className="row">
                    <div className="col s12 m7">
                        <form id="signupForm" onSubmit={handleSubmit}>
                            <div className="input-field">
                                <input type="email" id="signup-email" value={email}
                                onChange={(e) => setEmail(e.target.value)} required/>
                                <label htmlFor="signup-email">Email</label>
                            </div>
                            <div className="input-field">
                                <input type="password" id="signup-password" value={password}
                                onChange={(e) => setPassword(e.target.value)} required/>
                                <label htmlFor="signup-password">Password</label>
                            </div>
                            <div className="input-field">
                                <input type="password" id="signup-password2" value={password2}
                                onChange={(e) => setPassword2(e.target.value)} required/>
                                <label htmlFor="signup-password2">Confirm Password</label>
                            </div>
                            <div className="input-field">
                                <input type="text" id="signup-name" value={displayName}
                                onChange={(e) => setDisplayName(e.target.value)}/>
                                <label htmlFor="signup-name">Your name</label>
                            </div>
                            <button className="btn">Sign Up</button>
                            <p className="error pink-text center-align">{error} </p>
                        </form>
                    </div>
                    <div className="col s12 m5">
                        <span className="btn btn-block btn-social btn-google" onClick={handleSigninGoogle}>
                            <span className="fa fa-google"></span>
                            Sign Up with Google
                        </span>
                        <br/>
                        <span className="btn btn-block btn-social btn-facebook disabled" onClick={handleSigninGoogle}>
                            <span className="fa fa-facebook"></span>
                            Sign Up with Facebook
                        </span>
                        <br/>
                        <span className="btn btn-block btn-social btn-twitter disabled" onClick={handleSigninGoogle}>
                            <span className="fa fa-twitter"></span>
                            Sign Up with Twitter
                        </span>

                    </div>
                </div>
                <small>
                By signing in or creating an account, you agree with our Terms & Conditions and Privacy Statement
                </small>
            </div>
        </div>
    )
}

export default SignUpModal;