
// https://openlibrary.org/api/books?bibkeys=ISBN:9789724616995&jscmd=data&format=json
export async function getOpenLibraryBook(bookISBN)  {
    var url = "https://openlibrary.org"; 

    var params = {
        jscmd: "data",
        format: "json"
    };

    var bookInfo = '';

    url = url + "/api/books?bibkeys=ISBN:" + bookISBN;
    Object.keys(params).forEach((key) => {url += "&" + key + "=" + params[key];});
    console.log(url);
    
    await fetch(url)
        .then(function(response){return response.json();})
        .then(function(response) {
            console.log(response);
            const keys = Object.keys(response);
            bookInfo = (response[keys[0]]);
            // var pages = response.query.pages;
            // for (var page in pages) {
            //     if (page === "-1") {
            //         imagens.push('/images/Nophoto.jpg');
            //     } else {
            //         imagens.push(pages[page].thumbnail.source);
            //     }
                
            // }
        })
        .catch((error) => {
            console.log(error);
        });
    return bookInfo;
}

