import React, { useState, useEffect, useContext } from 'react';
import M from 'materialize-css';
import { UserContext } from '../../contexts/userContext'; 

const LogInModal = () => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState('');
    let loginModal = document.getElementById('modal-login');
    const { userLoginEmail, userPasswordReset, userLoginGoogle } = useContext(UserContext);

    useEffect(() => {
        M.Modal.init(loginModal);
    },[loginModal])

    const handleSigninGoogle = () => {
        userLoginGoogle()
    }

    const handlePasswordReset = () => {
        if (email !== '') {
            userPasswordReset(email)
                .then( () => {
                    M.toast({html: 'Message sent to your email'});
                })
                .catch( (err) => {
                    setError(err.message)
                })
        } else {
            setError('enter an email')
        }
        
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        userLoginEmail( email, password)
            .then((userAuth) => {
                setEmail('');
                setPassword('');
                setError('');
                document.getElementById('loginForm').reset();
                M.Modal.getInstance(loginModal).close();
            })
            .catch((err) => {
                // Handle Errors
                var errorMessage = err.message;
                setError(errorMessage);
            })
    }

    return (
        <div id="modal-login" className="modal">
            <div className="modal-content">
                <h4>Login</h4>
                <div className="row">
                    <div className="col s12 m7">
                        <form id="loginForm" onSubmit={handleSubmit}>
                            <div className="input-field">
                                <input type="email" id="login-email" onChange={(e) => setEmail(e.target.value)} required />
                                <label htmlFor="login-email">Email address</label>
                            </div>
                            <div className="input-field">
                                <input type="password" id="login-password" onChange={(e) => setPassword(e.target.value)} required />
                                <label htmlFor="login-password">Your password</label>
                            </div>
                            <button className="btn ">Login</button>
                            <a className="right" href="#!" onClick={handlePasswordReset}>Forgot your password?</a>
                            <p className="error pink-text center-align">{error}</p>
                        </form>
                    </div>
                    <div className="col s12 m5">
                        <span className="btn btn-block btn-social btn-google" onClick={handleSigninGoogle}>
                            <span className="fa fa-google"></span>
                            Sign Up with Google
                        </span>
                        <br/>
                        <span className="btn btn-block btn-social btn-facebook disabled" onClick={handleSigninGoogle}>
                            <span className="fa fa-facebook"></span>
                            Sign Up with Facebook
                        </span>
                        <br/>
                        <span className="btn btn-block btn-social btn-twitter disabled" onClick={handleSigninGoogle}>
                            <span className="fa fa-twitter"></span>
                            Sign Up with Twitter
                        </span>
                    </div>
                </div>
                <small>
                By signing in or creating an account, you agree with our Terms & Conditions and Privacy Statement
                </small>
            </div>
        </div>
    )
}

export default LogInModal;