import React, { useContext, useState, useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import M from 'materialize-css';
import { AuthorsContext } from '../../contexts/authorsContext'; 
import { UserContext } from '../../contexts/userContext'; 

const AuthorsList = () => {
    const { authors, authorDelete, authorSetOrder } = useContext(AuthorsContext);
    const [authorList, setAuthorList] = useState([]);
    const [author, setAuthor] = useState({});
    const [editLink, setEditLink] = useState('');
    const [bookLink, setBookLink] = useState('');
    const [quantBooks, setQuantBooks] = useState(0);
    const [order, setOrder] = useState(1);
    const [searchString, setSearchString] = useState('');
    let prevCountry = '';
    const { prefs } = useContext(UserContext);

    const compareAuthor = (a, b) => {
        const nameA = a.name.toUpperCase();
        const nameB = b.name.toUpperCase();

        let comparison = 0;
        if (nameA > nameB ) {
            comparison = 1;
        } else {
            comparison = -1;
        }
        return comparison;

    }

    const compareCountry = (a, b) => {
        if (!a.country) {a.country = ' '}
        if (!b.country) {b.country = ' '}
        const countryA = a.country.toUpperCase() + a.name.toUpperCase();
        const countryB = b.country.toUpperCase() + b.name.toUpperCase();

        let comparison = 0;
        if (countryA > countryB ) {
            comparison = 1;
        } else {
            comparison = -1;
        }
        return comparison;
    }

    const deleteAuthor = () => {
        const oldName = author.name;
        authorDelete(author.id)
            .then( () => {
                M.toast({html: oldName + ' deleted'}); 
            })
            .catch( (err) => {
                console.log("Error removing document: ", err);
            })
    }

    const onChangeOrder = (e) => {
        if (e.target.checked) {
            setOrder(2)
            prefs.authorsOrder = 2;
            authorSetOrder(2);
        } else {
            setOrder(1)
            prefs.authorsOrder = 1;
            authorSetOrder(1)
        }
        setAuthorList(authors);
    }

    const onChangeDisplayName = (e) => {
        let newAuthorList = [...authorList];
        let currentName = '';

        if (e.target.checked) {
            // setDisplayName(2);
            newAuthorList.forEach( (author) => {
                currentName = author.name;
                author.name = currentName.split(' ').slice(-1).join(' ') 
                            + ', ' 
                            + currentName.split(' ').slice(0, -1).join(' '); 
            })
        } else {
            // setDisplayName(1);
            newAuthorList.forEach( (author) => {
                currentName = author.name;
                currentName = currentName.replace(/,/g, "");
                author.name = currentName.split(' ').slice(1).join(' ') 
                            + ' '
                            + currentName.split(' ').slice(0, 1).join(' '); 
            })
        }
        if (order === 1) {
            newAuthorList.sort(compareAuthor)
        } else {
            newAuthorList.sort(compareCountry)
        }
        setAuthorList(newAuthorList);
    }
    
    const filterAuthors = (searchString) => {

        let newAuthorList = authors.filter((author) => {
            return ( 
                author.name.toUpperCase().includes(searchString.toUpperCase())
                ||
                (author.country && author.country.toUpperCase().includes(searchString.toUpperCase()) )
                ||
                (author.notes && author.notes.toUpperCase().includes(searchString.toUpperCase()) )
            )
        });
        if (order === 1) {
            newAuthorList.sort(compareAuthor)
        } else {
            newAuthorList.sort(compareCountry)
        }
        setAuthorList(newAuthorList);
    }

    const onSearchChange = (e) => {
        setSearchString(e.target.value);

        filterAuthors(e.target.value);
    }

    useEffect(() => {
        console.log('initial authorList load');
        setAuthorList(authors);
        if (prefs.authorsOrder) {
            setOrder(prefs.authorsOrder)
        }
    }, [authors, prefs])

    const handleClick = async (author) => {
        let elements = document.querySelectorAll('.modal');
        M.Modal.init(elements);
        let matimage = document.querySelectorAll('.materialboxed');
        M.Materialbox.init(matimage);

        setEditLink('/authors/create/'+author.id);
        setBookLink('/books/'+author.name);
        setAuthor(author);
        let authorModal = document.getElementById('modal-author-detail');
        document.getElementById('ModalAuthorName').innerText = author.name;
        if (author.country) {
            document.getElementById('ModalAuthorCountry').innerText = author.country;
        } else {
            document.getElementById('ModalAuthorCountry').innerText = '';
        }
        if (author.birth) {
            document.getElementById('ModalAuthorBirth').innerText = author.birth + '-' + author.death;
        } else {
            document.getElementById('ModalAuthorBirth').innerText = '';
        }
        if (author.notes) {
            document.getElementById('ModalAuthorNotes').innerText = author.notes;
        } else {
            document.getElementById('ModalAuthorNotes').innerText = '';
        }
        if (author.photo === '') {
            document.getElementById('ModalAuthorPhoto').src = 'images/Nophoto.jpg';
        } else {
            document.getElementById('ModalAuthorPhoto').src = author.photo;
            document.getElementById('ModalAuthorPhoto').setAttribute("data-caption", author.name);
        }
        document.getElementById('ModalAuthorPhoto').alt = author.name;

        setQuantBooks(author.bookList ? author.bookList.length : 0);
        M.Modal.getInstance(authorModal).open();
    }

    return (
        <div className="authorlists container">
        {/* <AuthorModalDetail /> */}
        <div id="modal-author-detail" className="modal">
            <div className="modal-content">
                <div className="row">
                    <div className="col s12 m4">
                        <img id="ModalAuthorPhoto" className="materialboxed" data-caption="Esra " src="images/Nophoto.jpg" alt="" width="100%" height="100%"/>
                    </div>
                    <div className="col s12 m6">
                        <h4 id="ModalAuthorName">Name...</h4>
                        <p id="ModalAuthorCountry"></p>
                        <p id="ModalAuthorBirth"></p>
                        <p id="ModalAuthorNotes"></p>
                    </div>
                </div>
           </div>
            {/* <div className="modal-footer"> */}
            <div id="ModalAuthorButtons" className="card-action grey lighten-4 grey-text">
                <NavLink to={editLink} className="modal-close waves-effect btn-flat">Edit</NavLink>
                {quantBooks === 0 &&  (
                    <NavLink to="#!" className="modal-close waves-effect btn-flat" onClick={deleteAuthor}>Delete</NavLink>
                )} 
                {quantBooks > 0 &&  (
                    <NavLink to={bookLink} className="modal-close waves-effect btn-flat">{quantBooks} Book</NavLink>
                )} 
                {/* <a href="#!" className="modal-close waves-effect btn-flat">1 Book lended</a>
                <a href="#!" className="modal-close waves-effect btn-flat">2 Books to buy</a> */}
            </div>
        </div>
        {/* <AuthorModalDetail /> */}
            <div className="fixed-action-btn">
            <NavLink to='/authors/create' className="btn-large btn-floating waves-effect waves-light"><i className="material-icons">add</i></NavLink>
            {/* <button className="btn-large btn-floating waves-effect waves-light"><i class="material-icons">add</i></button> */}
            </div>
            {/* search bar */}
            <div className="container">
                 <br></br>
            </div>
            <nav>
                <div className="nav-wrapper grey lighten-4">
                    <div className="input-field">
                        <input id="search" type="search" value={searchString} onChange={(e) => onSearchChange(e)}/>
                        <label className="label-icon" htmlFor="search"><i className="material-icons grey-text">search</i></label>
                        <i className="material-icons">close</i>
                    </div>
                </div>                
            </nav>
            <div className="switch">
                <label>
                Authors
                <input type="checkbox" checked={order-1} onChange={(e) => onChangeOrder(e)} />
                <span className="lever"></span>
                Countries
                </label>
                
                {(order-1) ? <NavLink to='/authors/map' className="btn-small waves-effect waves-light">Map</NavLink> : ''}


                <label className="right">
                First Name 
                <input type="checkbox" onChange={(e) => onChangeDisplayName(e)} />
                <span className="lever"></span>
                Last Name
                </label>
            </div>

            <ul className="collection">
                {authorList && authorList.map(author => {
                    let authorQuantBooks = author.bookList ? author.bookList.length : '';
                    let countryHeader = null;
                    const imgtag = (author.photo === '') ? 
                    (    <React.Fragment>
                            <i className="purple light-1 circle">{author.name[0] + author.name[author.name.lastIndexOf(' ') + 1]}</i>
                        </React.Fragment>)
                    : 
                    (   <React.Fragment>
                            <img src={author.photo} alt="" className="circle" />
                        </React.Fragment> );

                    if (order === 2 && author.country !== prevCountry) {
                        if (author.country) {
                            prevCountry = author.country
                            countryHeader = 
                            (
                                <React.Fragment>
                                    <li className="collection-header">
                                        <h5>{prevCountry}</h5>
                                    </li>
                                </React.Fragment>
                            );

                        }
                    }

                    return (
                        // <AuthorSummary author={author} key={author.id}/> 
                        // <Link className="collection-item avatar" to={'/authors/' + author.id}>
                        <div key={author.id}>
                            {countryHeader}
                            <a href="#!" className="collection-item avatar" key={author.id} data-target={author} onClick={(e) => handleClick(author)}>
                            <span className="badge">{authorQuantBooks}</span>
                            {imgtag}
                            <span className="title"><b>{author.name}</b></span>
                            <p>{author.country}</p>
                            </a>
                        </div>
                    )
                })}
            </ul>
        </div>    
    );
}

export default AuthorsList;