import React, { useState, useEffect, useContext } from 'react';
import { db } from "../Others/firebase";
import { getPageImages } from "../Others/get_page_images";
import { useHistory } from 'react-router-dom';
import { AuthorsContext } from '../../contexts/authorsContext'; 
import { UserContext } from '../../contexts/userContext'; 
import { Countries } from '../Others/Countries';
import M from 'materialize-css';

const CreateAuthor = (props) => {
    const [authorID, setAuthorID] = useState('');
    const [name, setName] = useState('');
    const [country, setCountry] = useState('');
    const [birth, setBirth] = useState('');
    const [death, setDeath] = useState('');
    const [notes, setNotes] = useState('');
    const [photo, setPhoto] = useState('/images/Nophoto.jpg');
    const [error, setError] = useState('');
    const { authorExist } = useContext(AuthorsContext);
    const { user } = useContext(UserContext);
    const history = useHistory();
    
    const loadAuthor = (ID) => {
        console.log(ID);
        setAuthorID(ID);

        db.collection("authors").doc(ID).get().then( (author) => {
            const currentAuthor = author.data();
            console.log(currentAuthor);
            if (currentAuthor.name) {
                setName(currentAuthor.name);
                document.getElementById('authorName').classList.add("input-auto-filled");
            }
            if (currentAuthor.country) {
                setCountry(currentAuthor.country);
                document.getElementById('authorCountry').classList.add("input-auto-filled");
            }
            if (currentAuthor.birth) {
                setBirth(currentAuthor.birth);
                document.getElementById('authorBirth').classList.add("input-auto-filled");
            }
            if (currentAuthor.death) {
                setDeath(currentAuthor.death);
                document.getElementById('authorDeath').classList.add("input-auto-filled");
            }
            if (currentAuthor.notes) {
                setNotes(currentAuthor.notes);
                document.getElementById('authorNotes').classList.add("input-auto-filled");
            }
            if (currentAuthor.photo) {
                setPhoto(currentAuthor.photo);
            }
            setError('');
            document.getElementById('CreateAuthorButton').disabled = false;

            M.updateTextFields();
            
        });
    };

    const onFieldChange = (e) => {
        e.target.classList.remove("input-auto-filled");
        switch (e.target.id) {
            case 'authorName':
                setName(e.target.value);
                if (e.target.value === '') {
                    document.getElementById('CreateAuthorButton').disabled = true;
                } else {
                    document.getElementById('CreateAuthorButton').disabled = false;
                }
                break;
            case 'authorCountry':
                setCountry(e.target.value);
                break;
            case 'authorBirth':
                setBirth(e.target.value);
                break;
            case 'authorDeath': 
                setDeath(e.target.value);
                break;
            case 'authorNotes':
                setNotes(e.target.value);
                break;
            default:

        }
    }

    const getWikipediaPhoto = async (name) => {
        const result = await getPageImages(name);
        return result;
    }

    const photoClick = async (e) => {
        console.log('searching photo...');
        const result = await getWikipediaPhoto(name);
        if (result) {
            setPhoto(result);
            document.getElementById('createAuthorPhoto').src = result;
        }
        console.log(user)
    }

    const nameVerify = async (e) => {
        if (name !== '') {
            if (!authorID) {
                if (authorExist(name)) {
                    document.getElementById('CreateAuthorButton').disabled = true;
                    setError('this author is already on our database');
                } else {
                    const result = await getWikipediaPhoto(name);
                    setError('');
                    if (result) {
                        setPhoto(result);
                        document.getElementById('createAuthorPhoto').src = result;
                    }
                }
            }
        }
    }

    const searchLocation = () => {
        console.log('click');
        const paises = Countries(country, 'pt');
        console.log(paises);
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        if (name === '') {
            console.log('Author needs a name');
        } else {
            if (authorID) {
                db.collection("authors").doc(authorID).update({
                    user: user, 
                    name, country, birth, death, notes, photo,
                    updatedAt: new Date()
                    })
                .then(() => {
                    setName('');
                    setCountry('');
                    setBirth('');
                    setDeath('');
                    setNotes('');
                    setPhoto('/images/Nophoto.jpg');
                    history.push("/authors");
                })

            } else {
                db.collection("authors").add({
                    user: user, 
                    name, country, birth, death, notes, photo,
                    createdAt: new Date()
                    })
                .then(() => {
                    setName('');
                    setCountry('');
                    setBirth('');
                    setDeath('');
                    setNotes('');
                    setPhoto('/images/Nophoto.jpg');
                    history.push("/authors");
                })
            }
        }
    }

    useEffect(() => {
        if (props.match.params.id) {
            // dont forget to unsubscribe callback()
            loadAuthor(props.match.params.id);    
        } else {
            return;
        }
    }, [props]);

    return (
        <div className="container section">
            <form onSubmit={handleSubmit} className="white">
                {/* <h5 className="grey-text text-farken-3">Edit Author</h5> */}
                <div className="row">
                    <div className="col s12 m4">
                        <img id="createAuthorPhoto" src={photo} alt="s" width="100%" height="100%" onClick={photoClick}/>
                        <div className="file-field input-field">
                            <div className="btn">
                                <span>File</span>
                                <input type="file" name="image"/>
                            </div>
                            <div className="file-path-wrapper">
                                <input className="file-path validate" type="text" placeholder="...in progress" />
                            </div>
                        </div>
                    </div>
                    <div className="col s12 m6">
                        <div className="input-field">
                            <i className="material-icons prefix">person</i>
                            <input type="text" id="authorName" value={name} onChange={(e) => onFieldChange(e)} onBlur={nameVerify}/>
                            <label htmlFor="authorName">Author name</label>
                        </div>
                        <div className="input-field">
                            <i className="material-icons prefix" onClick={searchLocation}>location_city</i>
                            <input type="text" id="authorCountry" value={country} className="autocomplete" onChange={(e) => onFieldChange(e)}/>
                            <label htmlFor="authorCountry">Author country</label>
                        </div>
                        <div className="input-field">
                            <i className="material-icons prefix">view_comfy</i>
                            <input type="text" id="authorBirth" value={birth} onChange={(e) => onFieldChange(e)}/>
                            <label htmlFor="authorBirth">Author born year</label>
                        </div>
                        <div className="input-field">
                            <i className="material-icons prefix">view_comfy</i>
                            <input type="text" id="authorDeath" value={death} onChange={(e) => onFieldChange(e)}/>
                            <label htmlFor="authorDeath">Author died year</label>
                        </div>
                        <div className="input-field">
                            <i className="material-icons prefix">message</i>
                            <textarea id="authorNotes" className="materialize-textarea" value={notes} onChange={(e) => onFieldChange(e)}></textarea>
                            <label htmlFor="authorNotes">Notes</label>
                        </div>
                        <div className="input-field center">
                            <button id="CreateAuthorButton" className="btn" disabled>Submit</button>
                        </div>
                        <p className="error pink-text center-align">{error}</p>
                    </div>
                </div>
            </form>
        </div>
    )
}

export default CreateAuthor;