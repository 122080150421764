import React from 'react';
import { NavLink } from 'react-router-dom';

const SignedOutMobile = () => {
    
    return (
        <div>
            <li><div className="user-view">
                <div className="background">
                    <img src="images/patrick-fore-0gkw_9fy0eQ-unsplash.jpg" width="100%" height="100%" alt=""/>
                </div>
                <NavLink to='/profile' className='btn-large btn-floating purple light-1'>?</NavLink>
                <span className="black-text name"> _</span>
                <span className="black-text email"> _</span>
            </div></li>
            {/* <li><NavLink to='/login' className="sidenav-close">Login</NavLink></li> */}
            {/* <li><NavLink to='/signup' className="sidenav-close">Signup</NavLink></li> */}
            <li><a href="#!" className="modal-trigger sidenav-close" data-target="modal-login">Login</a></li>
            <li><a href="#!" className="modal-trigger sidenav-close" data-target="modal-signup">Signup</a></li>
            <li><div className="divider"></div></li>
            <li><NavLink to='/' className="sidenav-close">About</NavLink></li>
        </div>
    )
}

export default SignedOutMobile;