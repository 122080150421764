import React from "react";
import { Switch, Route } from "react-router-dom";

// components
import PrivateRoute from './components/Others/privateRoute';
import AuthorsList from './components/Authors/AuthorsList';
import AuthorsMap from './components/Authors/AuthorsMap';
// import Teste from './components/Authors/Teste';
import CreateAuthor from './components/Authors/CreateAuthor';
import CreateBook from './components/Books/CreateBook';
import BooksList from './components/Books/BooksList';
import LogIn from './components/Auth/LogIn';
import SignUp from './components/Auth/SignUp';
import Main from './components/Layout/Main';
import Profile from './components/Layout/Profile';

const Routes = () => (

    <Switch>
        <Route path='/login' component={LogIn} />
        <Route path='/signup' component={SignUp} />
        <PrivateRoute exact path='/books' component={BooksList} />
        <PrivateRoute exact path='/books/create' component={CreateBook} />
        <PrivateRoute path='/books/create/:id' component={CreateBook} />
        <PrivateRoute path='/books/:id' component={BooksList} />
        <PrivateRoute exact path='/authors' component={AuthorsList} />
        <PrivateRoute exact path='/authors/create' component={CreateAuthor} />
        <PrivateRoute exact path='/authors/map' component={AuthorsMap} />
        <PrivateRoute path='/authors/create/:id' component={CreateAuthor} />
        <Route exact path='/profile' component={Profile} />
        <Route path='/' component={Main} />
    </Switch>

);

export default Routes;