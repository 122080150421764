import React from 'react';
import Slider from './Slider';
import Notifications from './Notifications';
import LogInModal from '../Auth/LogInModal';
import SignUpModal from '../Auth/SignUpModal';

const Main = () => {
   return (
        <div>
            <Slider />
            <Notifications />
            <LogInModal />
            <SignUpModal />
        </div>
    )
}

export default Main;