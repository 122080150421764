import React, {useContext, useState, useEffect} from 'react';
import moment from 'moment';
import { db } from "../Others/firebase";
import { UserContext } from '../../contexts/userContext'; 


const Notifications = () => {
    const [notifications, setNotifications] = useState([]);
    const { user } = useContext(UserContext);

    useEffect(() => {
        if (user) {
            // dont forget to unsubscribe callback()
            const unsubscribe = db.collection("notifications")
                .orderBy('createdAt','desc').limit(3).onSnapshot((snapshot) => {
                const newNotifications = snapshot.docs.map((doc) => ({
                    id: doc.id,
                    ...doc.data()
                }));

            setNotifications(newNotifications);
            })
            return () => unsubscribe()
        } else {
            return;
        }
    }, [user]);

    if (!user) return (<div className="container"></div>);

    return (
        <div className="container">
            <ul >
                {notifications && notifications.map(notification => {
                    return (
                        <li className="collection-item" key={notification.id}>
                            <span>{notification.userName} </span>
                            <span>{notification.content} </span>
                            <span className="note-date">{moment(notification.createdAt.toDate()).fromNow()}</span>
                               
                        </li>
                    )
                })}
            </ul>
        </div>
    )
}

export default Notifications;