import React, { createContext, useState, useEffect} from "react";
import { auth, db, signInWithGoogle } from "../components/Others/firebase";

const getUserDocument = (userAuth) => {
    return new Promise ( (resolve, reject) => {
        if (userAuth.uid) {
            db.collection('users').doc(userAuth.uid).get()
                .then( (doc) => {
                    if (doc.exists) {
                        resolve({user: userAuth.uid, ...doc.data()})
                    } else {
                        console.log('user not found. Gonna create it...');
                        console.log(userAuth);
                        updateUserDocument(userAuth.uid, {email: userAuth.email, 
                                                          displayName: userAuth.displayName, 
                                                          photoURL: userAuth.photoURL,
                                                          language:'en', 
                                                          provider: 'unknown',
                                                          prefs: {theme: 'standard',
                                                                  booksOrder: 1,
                                                                  authorsOrder: 1,
                                                                  authorsDisplay: 1}})
                            .then( () => {
                                resolve({user: userAuth.uid, 
                                         email: userAuth.email,
                                         displayName: userAuth.displayName,
                                         photoURL: userAuth.photoURL,
                                         language: 'en', 
                                         provider: 'unknown',
                                         prefs: {theme: 'standard',
                                                 booksOrder: 1,
                                                 authorsOrder: 1,
                                                 authorsDisplay: 1}})
                            })
                            .catch( (err) => {
                                reject(err);
                            })
                    }
                })
                .catch( (err) => {
                    reject(err);
                })
        } else {
            reject('user not informed')
        }
    })
}

const updateUserDocument = (user, data) => {
    return new Promise( (resolve, reject) => {
        if (user) {
            db.collection('users').doc(user).set({
                ...data,
                updatedAt: new Date()
            }, {merge:true})
            .then( () => {
                resolve()
            })
            .catch( (err) => {
                reject(err);
            })
        } else {
            reject('user not informed');
        }
    })
}

const deleteUserDocument = (userAuth) => {
    console.log('deleteUserDocument');
    return new Promise( (resolve, reject) => {
        if (userAuth) {
            db.collection('users').doc(userAuth).delete()
                .then( () => resolve())
                .catch( (err) => reject(err) )
        } else {
            reject('user not informed');
        }
    })
}

export const UserContext = createContext({
    user: null,
    email: null,
    displayName: null,
    photoURL: null,
    language: 'en',
    provider: null,
    prefs: {theme: 'standard',
            booksOrder: 1,
            authorsOrder: 1,
            authorsDisplay: 1}
});

const UserContextProvider = (props) => {
    const [state, setState] = useState({
        user: null,
        email: null,
        displayName: null,
        photoURL: null,
        language: 'en',
        provider: null,
        prefs: {theme: 'standard',
                booksOrder: 1,
                authorsOrder: 1,
                authorsDisplay: 1}
    });
    const [isAuthReady, setIsAuthReady] = useState(false);

    const setUserPhoto = async (newPhotoURL) => {
        await setState({...state, 
            photoURL: newPhotoURL, 
        });
        updateUserDocument(state.user, {photoURL: newPhotoURL})
    }

    const setUserLanguage = async (newLanguage) => {
        await setState({...state, 
            language: newLanguage, 
        });
        updateUserDocument(state.user, {language: newLanguage});
    }

    const onUserLogin = (userAuth) => {
        // console.log('onUserLogin-begin');
        if (!userAuth.uid) return null;

        getUserDocument(userAuth)
            .then( (user) => {
                // console.log('onUserLogin user :' + user.user);
                // console.log('onUserLogin display :' + user.displayName);
                setState( user );
            } )
            .catch( (err) => {
                console.log('error', err);
            });
        // console.log('onUserLogin-end');
    };

    const onUserlogout = async () => {
        setState({ 
            user: null, 
            email: null, 
            displayName: null, 
            photoURL: null,
            language: 'en', 
            provider: null,
            prefs: {theme: 'standard',
                    booksOrder: 1,
                    authorsOrder: 1,
                    authorsDisplay: 1}
        });
    };

    useEffect( () => {
        const unsubscribe = auth.getRedirectResult()
            .then( (result) => {
                if (result.additionalUserInfo.isNewUser) {
                    console.log('lets create a new user id');
                    updateUserDocument(result.user.uid, 
                        {email: result.user.email, 
                         photoURL: result.user.photoURL, 
                         displayName: result.user.displayName, 
                         language: result.additionalUserInfo.profile.locale, 
                         provider: result.additionalUserInfo.providerId,
                         prefs: {theme: 'standard',
                                 booksOrder: 1,
                                 authorsOrder: 1,
                                 authorsDisplay: 1}
                        })
                }
                console.log('getRedirectResult');
                console.log('result.user object');
                console.log(result);
            })
            .catch( (err) => {
                console.log('getRedirectResult');
                console.log(err);
            });
            return () => {
                unsubscribe();
            }
        }, []);

    useEffect( () => {
        const unsubscribe = auth.onAuthStateChanged(async userAuth => {
            setIsAuthReady(true);
            console.log('change user status begin');
            if (userAuth) {
                // console.log('fetching user info');
                onUserLogin(userAuth);
            } else {
                onUserlogout();
            }
            console.log('change user status end');
        });
        return () => {
            unsubscribe();
        }
    }, []);

    const userCreateEmail = (email, password, displayName) => {
        if (!displayName) {
            displayName = email;
        }
        return new Promise( (resolve, reject) => {
            auth.createUserWithEmailAndPassword(email, password)
                .then( (newUser) => {
                    const firebaseUser = auth.currentUser;
                    firebaseUser.updateProfile({
                        displayName: displayName
                    });
                    updateUserDocument(newUser.user.uid, 
                        {email: newUser.user.email, 
                         photoURL: newUser.user.photoURL, 
                         displayName: displayName, 
                         language:'en', 
                         provider: 'web',
                         prefs: {theme: 'standard',
                                 booksOrder: 1,
                                 authorsOrder: 1,
                                 authorsDisplay: 1}})
                        .then( () => {
                            resolve(newUser);
                        })
                        .catch( (err) => {
                            console.log('userCreateEmail')
                            console.log(err)
                            reject(err);
                        });
                })
                .catch( (err) => {
                    console.log('userCreateEmail')
                    console.log(err)
                    reject(err)
                })
        })
    }

    const userLoginEmail = (email, password) => {
        return new Promise( (resolve, reject) => {
            auth.signInWithEmailAndPassword( email, password)
                .then( (userAuth) => {
                    resolve(userAuth)
                })
                .catch( (err) => {
                    console.log('userLoginEmail');
                    console.log(err);
                    reject(err)
                })
        })
    }

    const userLoginGoogle = () => {
        signInWithGoogle();
    }

    const userLogout = () => {
        auth.signOut();
    }

    const userDelete = (userID) => {
        return new Promise( (resolve, reject) => {
            if (userID === state.user) {
                const firebaseUser = auth.currentUser;
                deleteUserDocument(userID)
                    .then( () => {
                        firebaseUser.delete()
                            .then( () => {
                                resolve()
                            })
                            .catch( (err) => {
                                console.log('userDelete')
                                console.log(err)
                                reject(err)
                            })
                    })
                    .catch( (err) => {
                        console.log('userDelete')
                        console.log(err)
                        reject(err)
                    })
            } else {
                reject('invalid user');
            }
        })
    }

    const userPasswordReset = (userEmail) => {
        return new Promise( (resolve, reject) => {
            auth.sendPasswordResetEmail(userEmail)
                .then( () => {
                    resolve()
                })
                .catch( (err) => {
                    reject(err)
                })
        })
        
    }

    return (
        <UserContext.Provider value={
            {...state, 
            isAuthReady: isAuthReady, 
            setUserLanguage: setUserLanguage,
            setUserPhoto: setUserPhoto,
            userLoginEmail: userLoginEmail,
            userCreateEmail: userCreateEmail,
            userLoginGoogle: userLoginGoogle,
            userLogout: userLogout,
            userDelete: userDelete,
            userPasswordReset: userPasswordReset
            }
        }>
            {props.children}
        </UserContext.Provider>
    );
}

export default UserContextProvider;